.t {
  transform: none;
}
[notice-modal] {
  text-align: center;
  color: #000;
}
[notice-modal] .dim {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  background-color: rgba(0, 0, 0, 0.3);
}
[notice-modal] .content {
  width: 18.75rem;
  min-height: 6.25rem;
  border-radius: 0.25rem;
  padding-top: 2.5rem;
  padding-right: 1.875rem;
  padding-bottom: 5rem;
  padding-left: 1.875rem;
  font-size: 1.5rem;
  background-color: #fff;
  position: absolute;
  left: 50vw;
  top: 50vh;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
}
[notice-modal] .content a {
  position: absolute;
  left: 50%;
  bottom: 1.5625rem;
  right: auto;
  top: auto;
  transform: translateX(-50%);
  padding-top: 0.625rem;
  padding-right: 0.9375rem;
  padding-bottom: 0.625rem;
  padding-left: 0.9375rem;
  display: inline-block;
  margin-top: 1.25rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  background-color: #111;
  color: #fff;
}
@media (min-width: 768px) {
  [notice-modal] .content {
    width: 26.25rem;
    padding-top: 3.75rem;
    padding-right: 1.875rem;
    padding-bottom: 7.5rem;
    padding-left: 1.875rem;
  }
  [notice-modal] .content a {
    bottom: 1.875rem;
  }
}
