.t {
  transform: none;
}
[cookie] {
  position: fixed;
  left: 0rem;
  bottom: 0rem;
  right: auto;
  top: auto;
  padding-top: 0.75rem;
  padding-right: 1.125rem;
  padding-bottom: 0.75rem;
  padding-left: 1.125rem;
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
  display: flex;
  justify-content: center;
}
[cookie] .holder {
  position: relative;
  padding-top: 1rem;
  padding-right: 1.5rem;
  padding-bottom: 4.125rem;
  padding-left: 1.5rem;
  background-color: #000;
  color: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: -0.015625rem;
  font-weight: 500;
  max-width: 43.75rem;
}
[cookie] p a {
  text-decoration: underline;
}
[cookie] .accept-btn {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  left: auto;
  top: auto;
  padding-top: 0.375rem;
  padding-right: 1.5rem;
  padding-bottom: 0.375rem;
  padding-left: 1.5rem;
  background-color: #393939;
  border-radius: 0.25rem;
  overflow: hidden;
}
