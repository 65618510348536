@import "less/base.css";
.t {
  transform: none;
}
@media (max-width: 359px) {
  .hidden-ms-down {
    display: none !important;
  }
}
@media (min-width: 360px) {
  .hidden-mm-up {
    display: none !important;
  }
}
@media (max-width: 413px) {
  .hidden-mm-down {
    display: none !important;
  }
}
@media (min-width: 414px) {
  .hidden-ml-up {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidden-ml-down {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .hidden-tp-up {
    display: none !important;
  }
}
@media (max-width: 1023px) {
  .hidden-tp-down {
    display: none !important;
  }
}
@media (min-width: 1024px) {
  .hidden-tl-up {
    display: none !important;
  }
}
@media (max-width: 1259px) {
  .hidden-tl-down {
    display: none !important;
  }
}
@media (min-width: 1260px) {
  .hidden-ds-up {
    display: none !important;
  }
}
@media (max-width: 1439px) {
  .hidden-ds-down {
    display: none !important;
  }
}
@media (min-width: 1440px) {
  .hidden-dm-up {
    display: none !important;
  }
}
@media (max-width: 1599px) {
  .hidden-dm-down {
    display: none !important;
  }
}
@media (min-width: 1600px) {
  .hidden-dl-up {
    display: none !important;
  }
}
@font-face {
  font-family: "Helvetica-Now-Display-Black";
  font-weight: 400;
  font-style: normal;
  src: url("/font/HelveticaNowDisplay-CnBlk.woff2") format("woff2"), url("/font/HelveticaNowDisplay-CnBlk.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Now";
  font-weight: 400;
  font-style: normal;
  src: url("/font/HelveticaNow-Regular.woff2") format("woff2"), url("/font/HelveticaNow-Regular.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Now";
  font-weight: 500;
  font-style: normal;
  src: url("/font/HelveticaNow-Medium.woff2") format("woff2"), url("/font/HelveticaNow-Medium.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Now";
  font-weight: 700;
  font-style: normal;
  src: url("/font/HelveticaNow-Bold.woff2") format("woff2"), url("/font/HelveticaNow-Bold.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Now";
  font-weight: 800;
  font-style: normal;
  src: url("/font/HelveticaNowTextExtraBold.woff2") format("woff2"), url("/font/HelveticaNowTextExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Now";
  font-weight: 900;
  font-style: normal;
  src: url("/font/HelveticaNow-Black.woff2") format("woff2"), url("/font/HelveticaNow-Black.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-kr";
  font-weight: 700;
  font-style: normal;
  src: url("/font/noto-sans-kr-v27-latin_korean-700.woff2") format("woff2"), url("/font/noto-sans-kr-v27-latin_korean-700.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-kr";
  font-weight: 500;
  font-style: normal;
  src: url("/font/noto-sans-kr-v27-latin_korean-500.woff2") format("woff2"), url("/font/noto-sans-kr-v27-latin_korean-500.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-kr";
  font-weight: 400;
  font-style: normal;
  src: url("/font/noto-sans-kr-v27-latin_korean-regular.woff2") format("woff2"), url("/font/noto-sans-kr-v27-latin_korean-regular.woff") format("woff");
}
@font-face {
  font-family: "NotoSans";
  font-weight: 700;
  font-style: normal;
  src: url("/font/noto-sans-v27-vietnamese_latin-700.woff2") format("woff2"), url("/font/noto-sans-v27-vietnamese_latin-700.woff") format("woff");
}
@font-face {
  font-family: "NotoSans";
  font-weight: 500;
  font-style: normal;
  src: url("/font/noto-sans-v27-vietnamese_latin-500.woff2") format("woff2"), url("/font/noto-sans-v27-vietnamese_latin-500.woff") format("woff");
}
@font-face {
  font-family: "NotoSans";
  font-weight: 400;
  font-style: normal;
  src: url("/font/noto-sans-v27-vietnamese_latin-regular.woff2") format("woff2"), url("/font/noto-sans-v27-vietnamese_latin-regular.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-jp";
  font-weight: 700;
  font-style: normal;
  src: url("/font/noto-sans-jp-v42-latin_japanese-700.woff2") format("woff2"), url("/font/noto-sans-jp-v42-latin_japanese-700.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-jp";
  font-weight: 500;
  font-style: normal;
  src: url("/font/noto-sans-jp-v42-latin_japanese-500.woff2") format("woff2"), url("/font/noto-sans-jp-v42-latin_japanese-500.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-jp";
  font-weight: 400;
  font-style: normal;
  src: url("/font/noto-sans-jp-v42-latin_japanese-regular.woff2") format("woff2"), url("/font/noto-sans-jp-v42-latin_japanese-regular.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-tc";
  font-weight: 700;
  font-style: normal;
  src: url("/font/noto-sans-tc-v26-latin_chinese-traditional-700.woff2") format("woff2"), url("/font/noto-sans-tc-v26-latin_chinese-traditional-700.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-tc";
  font-weight: 500;
  font-style: normal;
  src: url("/font/noto-sans-tc-v26-latin_chinese-traditional-500.woff2") format("woff2"), url("/font/noto-sans-tc-v26-latin_chinese-traditional-500.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-tc";
  font-weight: 400;
  font-style: normal;
  src: url("/font/noto-sans-tc-v26-latin_chinese-traditional-regular.woff2") format("woff2"), url("/font/noto-sans-tc-v26-latin_chinese-traditional-regular.woff") format("woff");
}
[play-viewer] {
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background-color: #1f1e1e;
}
[play-viewer] .viewer .info .text,
[play-viewer] .viewer .info a,
[play-viewer] .viewer .close,
[play-viewer] .viewer .page-move,
[play-viewer] .viewer .remote,
[play-viewer] .viewer .controls a,
[play-viewer] .viewer .video-controls {
  transition: opacity 1s ease;
}
[play-viewer] .viewer {
  position: relative;
}
[play-viewer] .viewer .info {
  position: absolute;
  width: 100%;
  left: 0rem;
  bottom: 0rem;
  right: auto;
  top: auto;
  box-sizing: border-box;
}
[play-viewer] .viewer .info.hide .inner {
  transform: translateY(100%);
}
[play-viewer] .viewer .info .inner {
  padding-top: 4.625rem;
  padding-right: 1.125rem;
  padding-bottom: 1.5rem;
  padding-left: 0.9375rem;
  transform: translateY(0rem);
  transition: transform 1s ease;
}
[play-viewer] .viewer .info .text {
  width: calc(100% - 83px);
  display: inline-block;
  position: relative;
  width: 50%;
}
[play-viewer] .viewer .info .text p:first-of-type {
  font-size: 0.625rem;
  font-weight: 500;
  padding-bottom: 0.5rem;
}
[play-viewer] .viewer .info .text p + p {
  font-size: 0.8125rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  font-weight: 500;
}
[play-viewer] .viewer .info a {
  background-color: #fff;
  border-radius: 1.25rem;
  padding-top: 0.5rem;
  padding-right: 0.875rem;
  padding-bottom: 0.5rem;
  padding-left: 0.875rem;
  position: absolute;
  right: 0.9375rem;
  bottom: 1.5rem;
  left: auto;
  top: auto;
  font-size: 0rem;
}
.no-touch [play-viewer] .viewer .info a:hover i {
  transform: rotate(180deg);
}
[play-viewer] .viewer .info a span {
  display: inline-block;
  color: #1c1c1c;
  font-size: 0.875rem;
  letter-spacing: -0.01875rem;
  font-weight: 500;
  vertical-align: middle;
}
#app:not(.ko) [play-viewer] .viewer .info a span {
  font-size: 0.8125rem;
}
[play-viewer] .viewer .info a i {
  width: 1rem;
  height: 1rem;
  margin-left: 0.125rem;
  display: inline-block;
  background-image: url('/img/common/ic-black-more.png');
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: middle;
  transition: transform 0.4s ease;
}
#app:not(.ko) [play-viewer] .viewer .info a i {
  width: 0.9375rem;
  height: 0.9375rem;
}
[play-viewer] .viewer .close {
  position: absolute;
  right: 1.125rem;
  top: 1.875rem;
  left: auto;
  bottom: auto;
  width: 1.25rem;
  height: 1.25rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/img/common/ic-white-close.png');
  transition: opacity 0.3s ease;
}
.no-touch [play-viewer] .viewer .close:hover {
  opacity: 0.6;
}
[play-viewer] .viewer .page-move {
  position: absolute;
  right: 0.9375rem;
  bottom: 6.875rem;
  left: auto;
  top: auto;
  opacity: 1;
}
[play-viewer] .viewer .page-move.full {
  opacity: 0;
}
[play-viewer] .viewer .page-move a {
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.no-touch [play-viewer] .viewer .page-move a:hover {
  opacity: 0.6;
}
[play-viewer] .viewer .page-move a.prev {
  margin-bottom: 0.75rem;
  background-image: url('/img/common/btn-contents-previous-prev.png');
}
[play-viewer] .viewer .page-move a.next {
  background-image: url('/img/common/btn-contents-previous-next.png');
}
[play-viewer] .viewer .page-move a.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
[play-viewer] .more-info {
  position: absolute;
  left: 0rem;
  top: -100%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  transition: top 0s ease;
  transition-delay: 1s;
}
[play-viewer] .more-info .inner {
  background-color: rgba(0, 0, 0, 0.8);
  padding-top: 1.875rem;
  padding-right: 1.125rem;
  padding-bottom: 2.625rem;
  padding-left: 1.125rem;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 1s ease;
}
[play-viewer] .more-info .inner .more-close {
  width: 1.25rem;
  height: 1.25rem;
  margin-bottom: 1.875rem;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/img/common/ic-backward-dark.png');
  transition: opacity 0.3s ease;
}
.no-touch [play-viewer] .more-info .inner .more-close:hover {
  opacity: 0.6;
}
[play-viewer] .more-info .inner [sns-share] {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1.125rem;
  top: 1.875rem;
  left: auto;
  bottom: auto;
}
[play-viewer] .more-info .inner [sns-share] a.btn {
  background-image: url('/img/common/sns-share-square-dark.png');
  background-repeat: no-repeat;
  background-size: contain;
}
[play-viewer] .more-info .inner [sns-share] .share-popup {
  top: 2.125rem;
}
[play-viewer] .more-info .inner .depth {
  margin-bottom: 1.125rem;
  font-size: 0.75rem;
  font-weight: 500;
}
[play-viewer] .more-info .inner .title {
  margin-bottom: 2.5rem;
  font-size: 1.125rem;
  line-height: 0.09375rem;
  letter-spacing: -0.01875rem;
  line-height: 1.5;
  font-weight: 500;
}
[play-viewer] .more-info .inner .content {
  font-size: 1rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  color: #c9c9c9;
}
[play-viewer] .more-info .inner .link {
  position: absolute;
  left: 1.125rem;
  bottom: 2.625rem;
  right: auto;
  top: auto;
  width: 90%;
}
[play-viewer] .more-info .inner .link span {
  font-size: 0.875rem;
  line-height: 0.09375rem;
  letter-spacing: -0.015625rem;
  line-height: 1.5;
  font-weight: 500;
  vertical-align: middle;
  display: inline-block;
}
[play-viewer] .more-info .inner .link i {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-left: 0.3125rem;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/img/common/ic-white-landing.png');
}
[play-viewer].more-open .viewer .close {
  opacity: 0;
}
[play-viewer].more-open .more-info {
  top: 0rem;
  transition-delay: 0s;
}
[play-viewer].more-open .more-info .inner {
  opacity: 1;
}
[play-viewer].play-wide .viewer,
[play-viewer].play-wide .more-info .inner {
  width: 100%;
  height: 100%;
}
[play-viewer].play-shorts .viewer,
[play-viewer].play-shortsWide .viewer,
[play-viewer].play-shorts .more-info .inner,
[play-viewer].play-shortsWide .more-info .inner {
  height: 100%;
  max-width: 100%;
  aspect-ratio: 0.5625;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
[play-viewer].play-shorts .viewer .info,
[play-viewer].play-shortsWide .viewer .info {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 23%, rgba(0, 0, 0, 0));
}
[play-viewer].play-shorts.more-open .viewer .info .text,
[play-viewer].play-shortsWide.more-open .viewer .info .text,
[play-viewer].play-shorts.more-open .viewer .info a,
[play-viewer].play-shortsWide.more-open .viewer .info a,
[play-viewer].play-shorts.more-open .viewer .page-move,
[play-viewer].play-shortsWide.more-open .viewer .page-move,
[play-viewer].play-shorts.more-open .viewer .remote,
[play-viewer].play-shortsWide.more-open .viewer .remote,
[play-viewer].play-shorts.more-open .viewer .controls a,
[play-viewer].play-shortsWide.more-open .viewer .controls a,
[play-viewer].play-shorts.more-open .viewer .video-controls,
[play-viewer].play-shortsWide.more-open .viewer .video-controls {
  opacity: 0;
}
[play-viewer].play-shorts.more-open .viewer .video-controls,
[play-viewer].play-shortsWide.more-open .viewer .video-controls {
  z-index: 0;
}
[play-viewer].play-shorts.more-open .more-info,
[play-viewer].play-shortsWide.more-open .more-info {
  top: 0rem;
  transition-delay: 0s;
}
[play-viewer].play-shorts.more-open .more-info .inner,
[play-viewer].play-shortsWide.more-open .more-info .inner {
  opacity: 1;
}
[play-viewer].play-shortsWide [cdn-video],
[play-viewer].play-shortsWide img {
  object-fit: contain;
}
@media (max-width: 767px) and (max-height: 515px) {
  [play-viewer].play-wide.more-open .more-info .inner {
    transform: translateX(0rem);
  }
  [play-viewer].play-wide .viewer .info {
    pointer-events: none;
  }
  [play-viewer].play-wide .viewer .info .inner {
    padding-top: 5.9375rem;
    padding-right: 3rem;
    padding-bottom: 3.75rem;
    padding-left: 3rem;
  }
  [play-viewer].play-wide .viewer .info a {
    right: 2.625rem;
    bottom: 3.75rem;
    left: auto;
    top: auto;
    border-radius: 2rem;
    pointer-events: fill;
  }
  [play-viewer].play-wide .viewer .close {
    right: 3rem;
    top: 2.625rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer].play-wide .viewer .page-move {
    right: 2.625rem;
    bottom: 8.75rem;
    left: auto;
    top: auto;
  }
  [play-viewer].play-wide .more-info .inner {
    padding-top: 2.5rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 3rem;
    width: 100%;
  }
  [play-viewer].play-wide .more-info .inner [sns-share] {
    right: 3rem;
    top: 2.6875rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer].play-wide .more-info .inner .depth {
    margin-bottom: 0.625rem;
    font-size: 0.75rem;
  }
  [play-viewer].play-wide .more-info .inner .title {
    margin-bottom: 1.625rem;
    font-size: 1rem;
    line-height: 0.09375rem;
    letter-spacing: -0.01875rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .more-info .inner .content {
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .more-info .inner .link {
    position: relative;
    left: 0rem;
    bottom: 0rem;
    right: auto;
    top: auto;
  }
  [play-viewer].play-wide .more-info .inner .link span {
    font-size: 0.75rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .more-info .inner .link i {
    margin-left: 0.375rem;
    width: 0.875rem;
    height: 0.875rem;
  }
}
@media (min-width: 768px) {
  [play-viewer] .viewer .info .inner {
    padding-top: 4.625rem;
    padding-right: 1.625rem;
    padding-bottom: 3.75rem;
    padding-left: 1.625rem;
  }
  [play-viewer] .viewer .info .text {
    width: 60%;
  }
  [play-viewer] .viewer .info .text p:first-of-type {
    font-size: 0.9375rem;
    padding-bottom: 0.75rem;
  }
  [play-viewer] .viewer .info .text p + p {
    font-size: 1.1875rem;
    line-height: 0.091875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.47;
  }
  [play-viewer] .viewer .info a {
    border-radius: 1.625rem;
    padding-top: 0.75rem;
    padding-right: 1.1875rem;
    padding-bottom: 0.75rem;
    padding-left: 1.1875rem;
    right: 1.5625rem;
    bottom: 3.75rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .info a span {
    font-size: 1.1875rem;
  }
  #app:not(.ko) [play-viewer] .viewer .info a span {
    font-size: 1.125rem;
  }
  [play-viewer] .viewer .info a i {
    width: 1.4375rem;
    height: 1.4375rem;
    margin-left: 0.25rem;
  }
  #app:not(.ko) [play-viewer] .viewer .info a i {
    width: 1.375rem;
    height: 1.375rem;
  }
  [play-viewer] .viewer .close {
    position: fixed;
    right: 2.25rem;
    top: 1.5rem;
    left: auto;
    bottom: auto;
    width: 1.75rem;
    height: 1.75rem;
  }
  [play-viewer] .viewer .page-move {
    position: absolute;
    right: -3.75rem;
    bottom: 10.625rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .page-move a {
    width: 2.5rem;
    height: 2.5rem;
  }
  [play-viewer] .viewer .page-move a.prev {
    margin-bottom: 0.875rem;
  }
  [play-viewer] .more-info .inner {
    padding-top: 3.3125rem;
    padding-right: 1.625rem;
    padding-bottom: 0rem;
    padding-left: 1.625rem;
  }
  [play-viewer] .more-info .inner .more-close {
    width: 1.875rem;
    height: 1.875rem;
    margin-bottom: 4.6875rem;
  }
  [play-viewer] .more-info .inner [sns-share] {
    width: 2rem;
    height: 2rem;
    right: 1.625rem;
    top: 3.25rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer] .more-info .inner [sns-share] .share-popup {
    top: 2.25rem;
  }
  [play-viewer] .more-info .inner .depth {
    margin-bottom: 1.4375rem;
    font-size: 0.9375rem;
  }
  [play-viewer] .more-info .inner .title {
    margin-bottom: 3.125rem;
    font-size: 1.375rem;
    line-height: 0.09375rem;
    letter-spacing: -0.011875rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .content {
    margin-bottom: 4rem;
    font-size: 1.25rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .link {
    position: relative;
    left: 0rem;
    bottom: 0rem;
    right: auto;
    top: auto;
  }
  [play-viewer] .more-info .inner .link span {
    font-size: 1.125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .link i {
    width: 1.4375rem;
    height: 1.4375rem;
    margin-left: 0.375rem;
  }
  [play-viewer].play-wide .viewer .info {
    pointer-events: none;
  }
  [play-viewer].play-wide .viewer .info .inner {
    padding: 2.25rem;
  }
  [play-viewer].play-wide .viewer .info .inner .text {
    width: 60%;
  }
  [play-viewer].play-wide .viewer .info .inner .text p:first-of-type {
    font-size: 0.625rem;
    padding-bottom: 0.5rem;
  }
  [play-viewer].play-wide .viewer .info .inner .text p + p {
    font-size: 0.8125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .viewer .info a {
    border-radius: 1.25rem;
    padding-top: 0.5rem;
    padding-right: 0.875rem;
    padding-bottom: 0.5rem;
    padding-left: 0.875rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: auto;
    top: auto;
    pointer-events: fill;
  }
  [play-viewer].play-wide .viewer .info a span {
    font-size: 0.875rem;
  }
  #app:not(.ko) [play-viewer].play-wide .viewer .info a span {
    font-size: 0.875rem;
  }
  [play-viewer].play-wide .viewer .info a i {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
  }
  #app:not(.ko) [play-viewer].play-wide .viewer .info a i {
    width: 1.0625rem;
    height: 1.0625rem;
  }
  [play-viewer].play-wide .viewer .close {
    right: 2.25rem;
    top: 1.875rem;
    left: auto;
    bottom: auto;
    width: 1.5rem;
    height: 1.5rem;
  }
  [play-viewer].play-wide .viewer .page-move {
    position: absolute;
    right: 2.25rem;
    bottom: 7.375rem;
    left: auto;
    top: auto;
  }
  [play-viewer].play-wide .viewer .page-move a {
    width: 1.875rem;
    height: 1.875rem;
  }
  [play-viewer].play-wide .viewer .page-move a.prev {
    margin-bottom: 0.75rem;
  }
  [play-viewer].play-wide .more-info .inner {
    padding-top: 2rem;
    padding-right: 2.125rem;
    padding-bottom: 0rem;
    padding-left: 2.125rem;
  }
  [play-viewer].play-wide .more-info .inner .more-close {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 3.625rem;
  }
  [play-viewer].play-wide .more-info .inner [sns-share] {
    width: 1.75rem;
    height: 1.75rem;
    right: 2.25rem;
    top: 1.875rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer].play-wide .more-info .inner [sns-share] .share-popup {
    top: 2.5rem;
  }
  [play-viewer].play-wide .more-info .inner .depth {
    margin-bottom: 1.125rem;
    font-size: 0.75rem;
  }
  [play-viewer].play-wide .more-info .inner .title,
  [play-viewer].play-wide .more-info .inner .content {
    width: 60%;
  }
  [play-viewer].play-wide .more-info .inner .title {
    margin-bottom: 2.5rem;
    font-size: 1.125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .more-info .inner .content {
    margin-bottom: 3.125rem;
    font-size: 1rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .more-info .inner .link {
    position: relative;
    left: 0rem;
    bottom: 0rem;
    right: auto;
    top: auto;
  }
  [play-viewer].play-wide .more-info .inner .link span {
    font-size: 0.875rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .more-info .inner .link i {
    width: 1.125rem;
    height: 1.125rem;
    margin-left: 0.25rem;
  }
  [play-viewer].play-shortsWide .viewer,
  [play-viewer].play-shortsWide .more-info .inner {
    aspect-ratio: auto;
  }
  [play-viewer].play-shortsWide .close {
    right: 1.625rem;
    top: 3.375rem;
    left: auto;
    bottom: auto;
    width: 1.75rem;
    height: 1.75rem;
  }
  [play-viewer].play-shortsWide [cdn-video] {
    object-fit: contain;
  }
}
@media (min-width: 768px) and (min-width: 768px) and (max-height: 768px) {
  [play-viewer].play-shorts .more-info .inner,
  [play-viewer].play-shortsWide .more-info .inner {
    position: absolute;
  }
  [play-viewer] .viewer .info .inner {
    padding-top: 3.5625rem;
    padding-right: 1.25rem;
    padding-bottom: 3rem;
    padding-left: 1.25rem;
  }
  [play-viewer] .viewer .info .text {
    width: 50%;
  }
  [play-viewer] .viewer .info .text p:first-of-type {
    font-size: 0.75rem;
    padding-bottom: 0.625rem;
  }
  [play-viewer] .viewer .info .text p + p {
    font-size: 0.9375rem;
    line-height: 0.091875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.47;
  }
  [play-viewer] .viewer .info a {
    border-radius: 1.25rem;
    padding-top: 0.5625rem;
    padding-right: 0.9375rem;
    padding-bottom: 0.5625rem;
    padding-left: 0.9375rem;
    right: 1.25rem;
    bottom: 3rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .info a span {
    font-size: 0.9375rem;
    letter-spacing: -0.02rem;
  }
  #app:not(.ko) [play-viewer] .viewer .info a span {
    font-size: 0.875rem;
  }
  [play-viewer] .viewer .info a i {
    width: 1.0625rem;
    height: 1.0625rem;
    margin-left: 0.125rem;
  }
  [play-viewer] .viewer .close {
    width: 1.5rem;
    height: 1.5rem;
    right: 3rem;
    top: 2.6875rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer] .viewer .page-move {
    position: fixed;
    right: 2.625rem;
    bottom: 6rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .page-move a {
    width: 2.5rem;
    height: 2.5rem;
  }
  [play-viewer] .viewer .page-move a.prev {
    margin-bottom: 0.875rem;
  }
  [play-viewer] .more-info .inner {
    padding-top: 2.6875rem;
    padding-right: 3.75rem;
    padding-bottom: 0rem;
    padding-left: 3rem;
    aspect-ratio: inherit;
    width: 50%;
    margin: 0;
    position: absolute;
    right: 0rem;
    top: 0rem;
    left: auto;
    bottom: auto;
    transform: translateX(100%);
    transition: opacity 1s ease, transform 1s ease;
  }
  [play-viewer] .more-info .inner .more-close {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 3.5rem;
    transform: rotate(180deg);
  }
  [play-viewer] .more-info .inner [sns-share] {
    width: 1.5rem;
    height: 1.5rem;
    right: 3rem;
    top: 2.6875rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer] .more-info .inner .depth {
    margin-bottom: 1.125rem;
    font-size: 0.75rem;
  }
  [play-viewer] .more-info .inner .title,
  [play-viewer] .more-info .inner .content {
    width: 100%;
  }
  [play-viewer] .more-info .inner .title {
    margin-bottom: 2.5rem;
    font-size: 1.125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .content {
    margin-bottom: 3.125rem;
    font-size: 1rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .link span {
    font-size: 0.875rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .link i {
    width: 1.125rem;
    height: 1.125rem;
    margin-left: 0.375rem;
  }
  [play-viewer].more-open .more-info .inner {
    transform: translateX(0rem);
  }
  [play-viewer].play-wide .viewer .info .inner {
    padding-top: 5.9375rem;
    padding-right: 3rem;
    padding-bottom: 3.75rem;
    padding-left: 3rem;
  }
  [play-viewer].play-wide .viewer .info a {
    right: 2.625rem;
    bottom: 3.75rem;
    left: auto;
    top: auto;
    border-radius: 2rem;
  }
  [play-viewer].play-wide .viewer .close {
    right: 3rem;
    top: 2.625rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer].play-wide .viewer .page-move {
    right: 2.625rem;
    bottom: 8.75rem;
    left: auto;
    top: auto;
  }
  [play-viewer].play-wide .more-info .inner {
    padding-top: 2.6875rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 3.75rem;
    width: 100%;
  }
  [play-viewer].play-wide .more-info .inner [sns-share] {
    right: 3rem;
    top: 2.6875rem;
    left: auto;
    bottom: auto;
  }
}
@media (min-width: 1024px) {
  [play-viewer] .viewer .info .inner {
    padding-top: 6.0625rem;
    padding-right: 2.125rem;
    padding-bottom: 5rem;
    padding-left: 2.125rem;
  }
  [play-viewer] .viewer .info .text p:first-of-type {
    font-size: 1.25rem;
    padding-bottom: 1rem;
  }
  [play-viewer] .viewer .info .text p + p {
    font-size: 1.5625rem;
    line-height: 0.091875rem;
    letter-spacing: -0.02625rem;
    line-height: 1.47;
  }
  [play-viewer] .viewer .info a {
    border-radius: 2.125rem;
    padding-top: 0.9375rem;
    padding-right: 1.5625rem;
    padding-bottom: 0.9375rem;
    padding-left: 1.5625rem;
    right: 3.75rem;
    bottom: 5.125rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .info a span {
    font-size: 1.5625rem;
    letter-spacing: -0.03375rem;
  }
  #app:not(.ko) [play-viewer] .viewer .info a span {
    font-size: 1.5rem;
  }
  [play-viewer] .viewer .info a i {
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0.25rem;
  }
  #app:not(.ko) [play-viewer] .viewer .info a i {
    width: 1.6875rem;
    height: 1.6875rem;
  }
  [play-viewer] .viewer .close {
    width: 1.75rem;
    height: 1.75rem;
  }
  [play-viewer] .viewer .page-move {
    right: -5.75rem;
    bottom: 13.75rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .page-move a {
    width: 3.75rem;
    height: 3.75rem;
  }
  [play-viewer] .viewer .page-move a.prev {
    margin-bottom: 1.3125rem;
  }
  [play-viewer] .more-info .inner {
    padding-top: 4.4375rem;
    padding-right: 2.25rem;
    padding-bottom: 0rem;
    padding-left: 2.25rem;
  }
  [play-viewer] .more-info .inner .more-close {
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 6.25rem;
  }
  [play-viewer] .more-info .inner [sns-share] {
    width: 2.6875rem;
    height: 2.6875rem;
    right: 2.1875rem;
    top: 4.3125rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer] .more-info .inner [sns-share] .share-popup {
    top: 3.25rem;
  }
  [play-viewer] .more-info .inner .depth {
    margin-bottom: 1.875rem;
    font-size: 1.25rem;
  }
  [play-viewer] .more-info .inner .title {
    margin-bottom: 4.25rem;
    font-size: 1.875rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .content {
    margin-bottom: 5.25rem;
    font-size: 1.6875rem;
    line-height: 0.0925rem;
    letter-spacing: -0.015625rem;
    line-height: 1.48;
  }
  [play-viewer] .more-info .inner .link span {
    font-size: 1.5rem;
    line-height: 0.091875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.47;
  }
  [play-viewer] .more-info .inner .link i {
    width: 1.875rem;
    height: 1.875rem;
    margin-left: 0.5rem;
  }
  [play-viewer].play-wide .viewer .info .inner {
    padding-top: 3rem;
    padding-right: 2.25rem;
    padding-bottom: 3rem;
    padding-left: 2.25rem;
  }
  [play-viewer].play-wide .viewer .info .inner .text p:first-of-type {
    font-size: 0.75rem;
    padding-bottom: 0.875rem;
  }
  [play-viewer].play-wide .viewer .info .inner .text p + p {
    font-size: 1rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .viewer .info a {
    border-radius: 1.25rem;
    padding-top: 0.5rem;
    padding-right: 1.125rem;
    padding-bottom: 0.625rem;
    padding-left: 1.125rem;
    right: 2.25rem;
    bottom: 3rem;
    left: auto;
    top: auto;
  }
  [play-viewer].play-wide .viewer .info a span {
    font-size: 1.125rem;
  }
  #app:not(.ko) [play-viewer].play-wide .viewer .info a span {
    font-size: 1.0625rem;
  }
  [play-viewer].play-wide .viewer .info a i {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.25rem;
  }
  #app:not(.ko) [play-viewer].play-wide .viewer .info a i {
    width: 1.25rem;
    height: 1.25rem;
  }
  [play-viewer].play-wide .viewer .close {
    right: 2.25rem;
    top: 1.875rem;
    left: auto;
    bottom: auto;
    width: 1.75rem;
    height: 1.75rem;
  }
  [play-viewer].play-wide .viewer .page-move {
    right: 2.25rem;
    bottom: 9rem;
    left: auto;
    top: auto;
  }
  [play-viewer].play-wide .viewer .page-move a {
    width: 2.5rem;
    height: 2.5rem;
  }
  [play-viewer].play-wide .viewer .page-move a.prev {
    margin-bottom: 0.875rem;
  }
  [play-viewer].play-wide .more-info .inner {
    width: 70%;
    padding-top: 1.875rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 3.75rem;
    position: absolute;
    right: 0rem;
    top: 0rem;
    left: auto;
    bottom: auto;
    transform: translateX(100%);
    opacity: 1;
    transition: opacity 1s ease, transform 1s ease;
  }
  [play-viewer].play-wide .more-info .inner .more-close {
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: 3.5rem;
    transform: rotate(180deg);
  }
  [play-viewer].play-wide .more-info .inner [sns-share] {
    width: 1.75rem;
    height: 1.75rem;
    right: 3rem;
    top: 1.875rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer].play-wide.more-open .more-info .inner {
    transform: translateX(0.0625rem);
  }
  [play-viewer].play-shortsWide .viewer .page-move {
    right: 1.25rem;
    bottom: 7.5rem;
    left: auto;
    top: auto;
  }
  [play-viewer].play-shortsWide .viewer .page-move a {
    width: 2.5rem;
    height: 2.5rem;
  }
  [play-viewer].play-shortsWide .viewer .page-move a.prev {
    margin-bottom: 0.875rem;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) and (max-height: 1024px) {
  [play-viewer].play-shorts .more-info .inner,
  [play-viewer].play-shortsWide .more-info .inner {
    position: absolute;
  }
  [play-viewer] .viewer .info .inner {
    padding-top: 3.5625rem;
    padding-right: 1.25rem;
    padding-bottom: 3rem;
    padding-left: 1.25rem;
  }
  [play-viewer] .viewer .info .text p:first-of-type {
    font-size: 0.75rem;
    padding-bottom: 0.625rem;
  }
  [play-viewer] .viewer .info .text p + p {
    font-size: 0.9375rem;
    line-height: 0.091875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.47;
  }
  [play-viewer] .viewer .info a {
    border-radius: 1.25rem;
    padding-top: 0.5625rem;
    padding-right: 0.9375rem;
    padding-bottom: 0.5625rem;
    padding-left: 0.9375rem;
    right: 1.25rem;
    bottom: 3rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .info a span {
    font-size: 0.9375rem;
    letter-spacing: -0.02rem;
  }
  #app:not(.ko) [play-viewer] .viewer .info a span {
    font-size: 0.875rem;
  }
  [play-viewer] .viewer .info a i {
    width: 1.0625rem;
    height: 1.0625rem;
    margin-left: 0.125rem;
  }
  #app:not(.ko) [play-viewer] .viewer .info a i {
    width: 1.125rem;
    height: 1.125rem;
  }
  [play-viewer] .viewer .close {
    width: 1.75rem;
    height: 1.75rem;
    right: 3rem;
    top: 2.6875rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer] .viewer .page-move {
    position: fixed;
    right: 2.625rem;
    bottom: 6rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .page-move a {
    width: 2.5rem;
    height: 2.5rem;
  }
  [play-viewer] .viewer .page-move a.prev {
    margin-bottom: 0.875rem;
  }
  [play-viewer] .more-info .inner {
    padding-top: 2.6875rem;
    padding-right: 3.75rem;
    padding-bottom: 0rem;
    padding-left: 3rem;
    aspect-ratio: inherit;
    width: 50%;
    margin: 0;
    position: absolute;
    right: 0rem;
    top: 0rem;
    left: auto;
    bottom: auto;
    transform: translateX(100%);
    transition: opacity 1s ease, transform 1s ease;
  }
  [play-viewer] .more-info .inner .more-close {
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: 3.5rem;
    transform: rotate(180deg);
  }
  [play-viewer] .more-info .inner [sns-share] {
    width: 1.75rem;
    height: 1.75rem;
    right: 3rem;
    top: 2.6875rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer] .more-info .inner [sns-share] .share-popup {
    top: 2.25rem;
  }
  [play-viewer] .more-info .inner .depth {
    margin-bottom: 1.125rem;
    font-size: 0.75rem;
  }
  [play-viewer] .more-info .inner .title,
  [play-viewer] .more-info .inner .content {
    width: 70%;
  }
  [play-viewer] .more-info .inner .title {
    margin-bottom: 2.5rem;
    font-size: 1.125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .content {
    margin-bottom: 3.125rem;
    font-size: 1rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .link span {
    font-size: 0.875rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .link i {
    width: 1.125rem;
    height: 1.125rem;
    margin-left: 0.375rem;
  }
  [play-viewer].more-open .more-info .inner {
    transform: translateX(0.0625rem);
  }
  [play-viewer].play-wide .viewer .info .inner {
    padding-top: 5.9375rem;
    padding-right: 3rem;
    padding-bottom: 5.9375rem;
    padding-left: 3rem;
  }
  [play-viewer].play-wide .viewer .info a {
    right: 2.625rem;
    bottom: 5.9375rem;
    left: auto;
    top: auto;
    border-radius: 2rem;
  }
  [play-viewer].play-wide .viewer .close {
    right: 3rem;
    top: 2.625rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer].play-wide .viewer .page-move {
    right: 2.625rem;
    bottom: 12.5rem;
    left: auto;
    top: auto;
  }
  [play-viewer].play-wide .more-info .inner {
    padding-top: 2.6875rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 3.75rem;
    width: 50%;
  }
  [play-viewer].play-wide .more-info .inner [sns-share] {
    right: 3rem;
    top: 2.6875rem;
    left: auto;
    bottom: auto;
  }
}
@media (min-width: 1440px) {
  [play-viewer].play-shorts .more-info .inner,
  [play-viewer].play-shortsWide .more-info .inner {
    position: absolute;
  }
  [play-viewer] .viewer .info .inner {
    padding-top: 3.5625rem;
    padding-right: 1.25rem;
    padding-bottom: 3rem;
    padding-left: 1.25rem;
  }
  [play-viewer] .viewer .info .text p:first-of-type {
    font-size: 0.75rem;
    padding-bottom: 0.625rem;
  }
  [play-viewer] .viewer .info .text p + p {
    font-size: 0.9375rem;
    line-height: 0.091875rem;
    letter-spacing: -0.015625rem;
    line-height: 1.47;
  }
  [play-viewer] .viewer .info a {
    border-radius: 1.25rem;
    padding-top: 0.5625rem;
    padding-right: 0.9375rem;
    padding-bottom: 0.5625rem;
    padding-left: 0.9375rem;
    right: 1.25rem;
    bottom: 3rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .info a span {
    font-size: 0.9375rem;
    letter-spacing: -0.02rem;
  }
  #app:not(.ko) [play-viewer] .viewer .info a span {
    font-size: 0.875rem;
  }
  [play-viewer] .viewer .info a i {
    width: 1.0625rem;
    height: 1.0625rem;
    margin-left: 0.125rem;
  }
  #app:not(.ko) [play-viewer] .viewer .info a i {
    width: 1rem;
    height: 1rem;
  }
  [play-viewer] .viewer .close {
    width: 1.75rem;
    height: 1.75rem;
    right: 3rem;
    top: 2.6875rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer] .viewer .page-move {
    position: fixed;
    right: 2.625rem;
    bottom: 6rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .page-move a {
    width: 2.5rem;
    height: 2.5rem;
  }
  [play-viewer] .viewer .page-move a.prev {
    margin-bottom: 0.875rem;
  }
  [play-viewer] .more-info .inner {
    padding-top: 2.6875rem;
    padding-right: 3.75rem;
    padding-bottom: 0rem;
    padding-left: 3rem;
    aspect-ratio: inherit;
    width: 50%;
    margin: 0;
    position: absolute;
    right: 0rem;
    top: 0rem;
    left: auto;
    bottom: auto;
    transform: translateX(100%);
    transition: opacity 1s ease, transform 1s ease;
  }
  [play-viewer] .more-info .inner .more-close {
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: 3.5rem;
    transform: rotate(180deg);
  }
  [play-viewer] .more-info .inner [sns-share] {
    width: 1.75rem;
    height: 1.75rem;
    right: 3rem;
    top: 2.6875rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer] .more-info .inner [sns-share] .share-popup {
    top: 2.25rem;
  }
  [play-viewer] .more-info .inner .depth {
    margin-bottom: 1.125rem;
    font-size: 0.75rem;
  }
  [play-viewer] .more-info .inner .title,
  [play-viewer] .more-info .inner .content {
    width: 70%;
  }
  [play-viewer] .more-info .inner .title {
    margin-bottom: 2.5rem;
    font-size: 1.125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .content {
    margin-bottom: 3.125rem;
    font-size: 1rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .link span {
    font-size: 0.875rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .link i {
    width: 1.125rem;
    height: 1.125rem;
    margin-left: 0.375rem;
  }
  [play-viewer].more-open .more-info .inner {
    transform: translateX(0.0625rem);
  }
  [play-viewer].play-wide .viewer .info .inner {
    padding-top: 5.9375rem;
    padding-right: 3rem;
    padding-bottom: 5.9375rem;
    padding-left: 3rem;
  }
  [play-viewer].play-wide .viewer .info a {
    right: 2.625rem;
    bottom: 5.9375rem;
    left: auto;
    top: auto;
    border-radius: 2rem;
  }
  [play-viewer].play-wide .viewer .info a i {
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 0.25rem;
  }
  #app:not(.ko) [play-viewer].play-wide .viewer .info a i {
    width: 1.5rem;
    height: 1.5rem;
  }
  [play-viewer].play-wide .viewer .close {
    right: 3rem;
    top: 2.625rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer].play-wide .viewer .page-move {
    right: 2.625rem;
    bottom: 12.5rem;
    left: auto;
    top: auto;
  }
  [play-viewer].play-wide .more-info .inner {
    padding-top: 2.6875rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 3.75rem;
    width: 50%;
  }
  [play-viewer].play-wide .more-info .inner [sns-share] {
    right: 3rem;
    top: 2.6875rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer].more-open .more-info .inner {
    transform: translateX(0rem);
  }
}
@media (min-width: 1600px) {
  [play-viewer] .viewer .info .inner {
    padding-top: 4.5625rem;
    padding-right: 1.625rem;
    padding-bottom: 4rem;
    padding-left: 1.625rem;
  }
  [play-viewer] .viewer .info .text p:first-of-type {
    font-size: 1.0625rem;
    padding-bottom: 0.875rem;
  }
  [play-viewer] .viewer .info .text p + p {
    font-size: 1.25rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .viewer .info a {
    border-radius: 1.75rem;
    padding-top: 0.75rem;
    padding-right: 1.25rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    right: 1.625rem;
    bottom: 4rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .info a span {
    font-size: 1.25rem;
    letter-spacing: -0.02625rem;
  }
  [play-viewer] .viewer .info a span.more-en {
    font-size: 1.1875rem;
  }
  [play-viewer] .viewer .info a i {
    width: 1.4375rem;
    height: 1.4375rem;
    margin-left: 0.125rem;
  }
  [play-viewer] .viewer .close {
    width: 2.3125rem;
    height: 2.3125rem;
    right: 3.75rem;
    top: 3.5rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer] .viewer .page-move {
    position: fixed;
    right: 3.25rem;
    bottom: 8rem;
    left: auto;
    top: auto;
  }
  [play-viewer] .viewer .page-move a {
    width: 3.3125rem;
    height: 3.3125rem;
  }
  [play-viewer] .viewer .page-move a.prev {
    margin-bottom: 1.25rem;
  }
  [play-viewer] .more-info .inner {
    padding-top: 3.5625rem;
    padding-right: 5rem;
    padding-bottom: 0rem;
    padding-left: 3.75rem;
  }
  [play-viewer] .more-info .inner .more-close {
    width: 2.3125rem;
    height: 2.3125rem;
    margin-bottom: 4.6875rem;
  }
  [play-viewer] .more-info .inner [sns-share] {
    width: 1.75rem;
    height: 1.75rem;
    right: 3.75rem;
    top: 3.5625rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer] .more-info .inner .depth {
    margin-bottom: 1.4375rem;
    font-size: 1rem;
  }
  [play-viewer] .more-info .inner .title {
    margin-bottom: 3.3125rem;
    font-size: 1.5rem;
    line-height: 0.09375rem;
    letter-spacing: -0.020625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .content {
    margin-bottom: 4.1875rem;
    font-size: 1.3125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .link span {
    font-size: 1.1875rem;
    line-height: 0.09375rem;
    letter-spacing: -0.020625rem;
    line-height: 1.5;
  }
  [play-viewer] .more-info .inner .link i {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.625rem;
  }
  [play-viewer].play-wide .viewer .info .inner {
    padding-top: 8.4375rem;
    padding-right: 4rem;
    padding-bottom: 8.4375rem;
    padding-left: 4rem;
  }
  [play-viewer].play-wide .viewer .info .inner .text p:first-of-type {
    font-size: 1rem;
    padding-bottom: 1.125rem;
  }
  [play-viewer].play-wide .viewer .info .inner .text p + p {
    font-size: 1.3125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .viewer .info a {
    border-radius: 2.25rem;
    padding-top: 0.625rem;
    padding-right: 1.5rem;
    padding-bottom: 0.625rem;
    padding-left: 1.5rem;
    right: 3.5rem;
    bottom: 8.4375rem;
    left: auto;
    top: auto;
  }
  [play-viewer].play-wide .viewer .info a span {
    font-size: 1.5rem;
  }
  #app:not(.ko) [play-viewer].play-wide .viewer .info a span {
    font-size: 1.4375rem;
  }
  [play-viewer].play-wide .viewer .info a i {
    width: 1.6875rem;
    height: 1.6875rem;
    margin-left: 0.375rem;
  }
  [play-viewer].play-wide .viewer .close {
    right: 3.75rem;
    top: 3.5rem;
    left: auto;
    bottom: auto;
    width: 2.3125rem;
    height: 2.3125rem;
  }
  [play-viewer].play-wide .viewer .page-move {
    right: 3.5rem;
    bottom: 15.4375rem;
    left: auto;
    top: auto;
  }
  [play-viewer].play-wide .viewer .page-move a {
    width: 3.3125rem;
    height: 3.3125rem;
  }
  [play-viewer].play-wide .viewer .page-move a.prev {
    margin-bottom: 1.1875rem;
  }
  [play-viewer].play-wide .more-info .inner {
    padding-top: 3.5625rem;
    padding-right: 0rem;
    padding-bottom: 0rem;
    padding-left: 5rem;
  }
  [play-viewer].play-wide .more-info .inner .more-close {
    width: 2.3125rem;
    height: 2.3125rem;
    margin-bottom: 4.6875rem;
  }
  [play-viewer].play-wide .more-info .inner [sns-share] {
    width: 2.3125rem;
    height: 2.3125rem;
    right: 3.75rem;
    top: 3.5625rem;
    left: auto;
    bottom: auto;
  }
  [play-viewer].play-wide .more-info .inner [sns-share] .share-popup {
    top: 2.875rem;
  }
  [play-viewer].play-wide .more-info .inner .depth {
    margin-bottom: 1.4375rem;
    font-size: 1rem;
  }
  [play-viewer].play-wide .more-info .inner .title {
    margin-bottom: 3.3125rem;
    font-size: 1.5rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .more-info .inner .content {
    margin-bottom: 4.1875rem;
    font-size: 1.3125rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .more-info .inner .link {
    position: relative;
    left: 0rem;
    bottom: 0rem;
    right: auto;
    top: auto;
  }
  [play-viewer].play-wide .more-info .inner .link span {
    font-size: 1.1875rem;
    line-height: 0.09375rem;
    letter-spacing: -0.015625rem;
    line-height: 1.5;
  }
  [play-viewer].play-wide .more-info .inner .link i {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.625rem;
  }
}
html {
  font-size: 16px;
}
html,
body {
  width: 100%;
  height: 100%;
}
#app.ko,
#app.en,
#app.vn {
  word-break: keep-all;
}
#app[data-server-rendered] {
  display: none;
}
#app.ko,
#app.ko button {
  font-family: 'Helvetica Now', 'NotoSans-kr', sans-serif;
}
#app.en,
#app.en button {
  font-family: 'Helvetica Now', 'NotoSans-kr', sans-serif;
}
#app.jp,
#app.jp button {
  font-family: 'Helvetica Now', 'NotoSans-jp', 'NotoSans-kr', sans-serif;
}
#app.vn,
#app.vn button {
  font-family: 'Helvetica Now', 'NotoSans', 'NotoSans-kr', sans-serif;
}
#app.tw,
#app.tw button {
  font-family: 'Helvetica Now', 'NotoSans-tc', 'NotoSans-kr', sans-serif;
}
#app.dark,
#app.viewer,
#app.dark main {
  background-color: #000;
  color: #fff;
}
.lng-menu-li {
  cursor: pointer;
}
.lng-menu-li:nth-of-type(1) {
  font-family: 'NotoSans-kr', sans-serif;
}
.lng-menu-li:nth-of-type(2) {
  font-family: 'Helvetica Now', sans-serif;
}
.lng-menu-li:nth-of-type(3) {
  font-family: 'NotoSans-jp', sans-serif;
}
.lng-menu-li:nth-of-type(4) {
  font-family: 'NotoSans-tc', sans-serif;
}
.lng-menu-li:nth-of-type(5) {
  font-family: 'NotoSans', sans-serif;
}
.lng-menu-li.on {
  font-weight: 700;
}
.load-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  background-size: cover;
}
.load-news {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  background-color: #efefef;
}
.ir {
  text-indent: -10000px;
  font-size: 0;
  line-height: 0;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}
.loading i {
  display: block;
  width: 5rem;
  height: 5rem;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top-color: #000;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}
.dark .loading i {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
