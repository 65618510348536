@import "base.css";
@import "proj";
@import "responsive";
@import "font";
@import "viewer";

html { font-size: 16px; }
html,body{width:100%; height:100%; }

#app.ko,#app.en,#app.vn{word-break: keep-all;}
#app[data-server-rendered] { .hide; }
#app.ko, #app.ko button { font-family: 'Helvetica Now', 'NotoSans-kr', sans-serif; }
#app.en, #app.en button { font-family: 'Helvetica Now', 'NotoSans-kr', sans-serif; }
#app.jp, #app.jp button { font-family: 'Helvetica Now', 'NotoSans-jp', 'NotoSans-kr', sans-serif; }
#app.vn, #app.vn button { font-family: 'Helvetica Now', 'NotoSans', 'NotoSans-kr', sans-serif; }
#app.tw, #app.tw button { font-family: 'Helvetica Now', 'NotoSans-tc', 'NotoSans-kr', sans-serif; }

#app.dark, #app.viewer, #app.dark main { .bgc(#000); color: #fff; }

.lng-menu-li{.pointer;
  &:nth-of-type(1){.ff-kr;}
  &:nth-of-type(2){.ff-en;}
  &:nth-of-type(3){.ff-ja;}
  &:nth-of-type(4){.ff-zh;}
  &:nth-of-type(5){.ff-vi;}
  &.on{.bold;}
}
.load-img{.f; .abs; .lt(0,0); .cover; }
.load-news{.f; .abs; .lt(0,0); .bgc(#efefef);}

.ir { .ir; }