@import "vars";

@font-face {
  font-family: "Helvetica-Now-Display-Black";
  font-weight: 400;
  font-style: normal;
  src:
    url("/font/HelveticaNowDisplay-CnBlk.woff2") format("woff2"),
    url("/font/HelveticaNowDisplay-CnBlk.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Now";
  font-weight: 400;
  font-style: normal;
  src:
    url("/font/HelveticaNow-Regular.woff2") format("woff2"),
    url("/font/HelveticaNow-Regular.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Now";
  font-weight: 500;
  font-style: normal;
  src:
    url("/font/HelveticaNow-Medium.woff2") format("woff2"),
    url("/font/HelveticaNow-Medium.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Now";
  font-weight: 700;
  font-style: normal;
  src:
    url("/font/HelveticaNow-Bold.woff2") format("woff2"),
    url("/font/HelveticaNow-Bold.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Now";
  font-weight: 800;
  font-style: normal;
  src:
    url("/font/HelveticaNowTextExtraBold.woff2") format("woff2"),
    url("/font/HelveticaNowTextExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Now";
  font-weight: 900;
  font-style: normal;
  src:
    url("/font/HelveticaNow-Black.woff2") format("woff2"),
    url("/font/HelveticaNow-Black.woff") format("woff");
}

@font-face {
  font-family: "NotoSans-kr";
  font-weight: 700;
  font-style: normal;
  src:
    url("/font/noto-sans-kr-v27-latin_korean-700.woff2") format("woff2"),
    url("/font/noto-sans-kr-v27-latin_korean-700.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-kr";
  font-weight: 500;
  font-style: normal;
  src:
    url("/font/noto-sans-kr-v27-latin_korean-500.woff2") format("woff2"),
    url("/font/noto-sans-kr-v27-latin_korean-500.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-kr";
  font-weight: 400;
  font-style: normal;
  src:
    url("/font/noto-sans-kr-v27-latin_korean-regular.woff2") format("woff2"),
    url("/font/noto-sans-kr-v27-latin_korean-regular.woff") format("woff");
}


@font-face {
  font-family: "NotoSans";
  font-weight: 700;
  font-style: normal;
  src:
    url("/font/noto-sans-v27-vietnamese_latin-700.woff2") format("woff2"),
    url("/font/noto-sans-v27-vietnamese_latin-700.woff") format("woff");
}
@font-face {
  font-family: "NotoSans";
  font-weight: 500;
  font-style: normal;
  src:
    url("/font/noto-sans-v27-vietnamese_latin-500.woff2") format("woff2"),
    url("/font/noto-sans-v27-vietnamese_latin-500.woff") format("woff");
}
@font-face {
  font-family: "NotoSans";
  font-weight: 400;
  font-style: normal;
  src:
    url("/font/noto-sans-v27-vietnamese_latin-regular.woff2") format("woff2"),
    url("/font/noto-sans-v27-vietnamese_latin-regular.woff") format("woff");
}


@font-face {
  font-family: "NotoSans-jp";
  font-weight: 700;
  font-style: normal;
  src:
    url("/font/noto-sans-jp-v42-latin_japanese-700.woff2") format("woff2"),
    url("/font/noto-sans-jp-v42-latin_japanese-700.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-jp";
  font-weight: 500;
  font-style: normal;
  src:
    url("/font/noto-sans-jp-v42-latin_japanese-500.woff2") format("woff2"),
    url("/font/noto-sans-jp-v42-latin_japanese-500.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-jp";
  font-weight: 400;
  font-style: normal;
  src:
    url("/font/noto-sans-jp-v42-latin_japanese-regular.woff2") format("woff2"),
    url("/font/noto-sans-jp-v42-latin_japanese-regular.woff") format("woff");
}


@font-face {
  font-family: "NotoSans-tc";
  font-weight: 700;
  font-style: normal;
  src:
    url("/font/noto-sans-tc-v26-latin_chinese-traditional-700.woff2") format("woff2"),
    url("/font/noto-sans-tc-v26-latin_chinese-traditional-700.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-tc";
  font-weight: 500;
  font-style: normal;
  src:
    url("/font/noto-sans-tc-v26-latin_chinese-traditional-500.woff2") format("woff2"),
    url("/font/noto-sans-tc-v26-latin_chinese-traditional-500.woff") format("woff");
}
@font-face {
  font-family: "NotoSans-tc";
  font-weight: 400;
  font-style: normal;
  src:
    url("/font/noto-sans-tc-v26-latin_chinese-traditional-regular.woff2") format("woff2"),
    url("/font/noto-sans-tc-v26-latin_chinese-traditional-regular.woff") format("woff");
}