.t {
  transform: none;
}
[app-header] {
  position: fixed;
  z-index: 49;
}
[app-header].viewer,
[app-header].search {
  display: none;
}
[app-header].roll header {
  transform: translateY(-100%);
}
[app-header] header {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 3.75rem;
  transform: translateY(0rem);
  transition: transform 0.6s ease;
}
[app-header] header .inner {
  position: relative;
  padding-top: 0.875rem;
  padding-right: 1.125rem;
  padding-bottom: 0.875rem;
  padding-left: 1.125rem;
  width: 100%;
  height: 100%;
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  font-size: 0rem;
}
[app-header] header .inner .home {
  display: inline-block;
  width: 2.3125rem;
  height: 1.25rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('/img/common/header-nc-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
[app-header] header .inner .h-tab {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  right: auto;
  bottom: auto;
  white-space: nowrap;
}
[app-header] header .inner .h-tab a {
  display: inline-block;
  margin-top: 0rem;
  margin-right: 0.375rem;
  margin-bottom: 0rem;
  margin-left: 0.375rem;
  color: #a9a9a9;
  font-size: 0.9375rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
  position: relative;
  transition: all 0.3s ease;
}
[app-header] header .inner .h-tab a span {
  width: 0rem;
  height: 0.125rem;
  position: absolute;
  left: 50%;
  bottom: 0rem;
  right: auto;
  top: auto;
  transform: translateX(-50%);
  background-color: #a9a9a9;
  transition: width 0.8s cubic-bezier(0.86, 0, 0.07, 0.995), background-color 0.3s ease;
}
.no-touch [app-header] header .inner .h-tab a:hover {
  color: #1e1e1e;
}
[app-header] header .inner .h-tab a.router-link-active {
  color: #1e1e1e;
  font-weight: 700;
}
[app-header] header .inner .h-tab a.router-link-active span {
  width: 100%;
  background-color: #1e1e1e;
}
[app-header] header .inner .h-tab.nc-creators a:nth-of-type(2) {
  color: #a9a9a9;
  font-weight: 500;
}
[app-header] header .inner .h-tab.nc-creators a:nth-of-type(2) span {
  width: 0rem;
  background-color: #1e1e1e;
}
[app-header] header .inner .h-btn {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 1.25rem;
}
[app-header] header .inner .h-btn a {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  display: inline-block;
  transition: opacity 0.3s ease;
}
.no-touch [app-header] header .inner .h-btn a:hover {
  opacity: 0.6;
}
[app-header] header .inner .h-btn a.search {
  background-image: url('/img/common/header-search-icon.png');
}
[app-header] header .inner .h-btn a.menu {
  background-image: url('/img/common/header-menu-icon.png');
}
[app-header] [header-search] {
  transform: translateY(-100%);
  opacity: 0;
  transition: opacity 0.4s ease, transform 0s ease;
  transition-delay: 0s,1s;
}
[app-header] [header-search].open {
  transform: translateY(0%);
  opacity: 1;
  transition-delay: 0s,0s;
}
[app-header] .gnb {
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #fff;
  left: 0rem;
  top: -150%;
  right: auto;
  bottom: auto;
  overflow-y: auto;
  padding-top: 0rem;
  padding-right: 1.125rem;
  padding-bottom: 1.5625rem;
  padding-left: 1.125rem;
  box-sizing: border-box;
  color: #1e1e1e;
  opacity: 0;
  transition: opacity 1s ease, top 0s ease;
  transition-delay: 0.5s, 1.5s;
}
[app-header] .gnb .inner {
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  position: relative;
  top: -100%;
  transition-delay: 1s;
}
[app-header] .gnb .close {
  position: fixed;
  right: 1.125rem;
  top: 1.25rem;
  left: auto;
  bottom: auto;
  width: 1.25rem;
  height: 1.25rem;
  z-index: 9;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/img/common/ic-close.png');
  background-color: transparent;
  transform: translateY(-100px);
  transition: opacity 0.3s ease, transform 0s ease;
  transition-delay: 0s, 1s;
}
.no-touch [app-header] .gnb .close:hover {
  opacity: 0.6;
}
[app-header] .gnb li,
[app-header] .gnb a {
  color: #1e1e1e;
}
[app-header] .gnb ul li.label {
  display: block;
}
[app-header] .gnb ul li.label span:after {
  display: none;
}
[app-header] .gnb ul li a,
[app-header] .gnb ul li span {
  display: inline-block;
  position: relative;
}
[app-header] .gnb ul li a:after,
[app-header] .gnb ul li span:after {
  content: "";
  width: 0rem;
  height: 0.0625rem;
  background-color: #1e1e1e;
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  transition: width 0.6s cubic-bezier(0.49, -0.01, 0.07, 1);
}
.no-touch [app-header] .gnb ul li a:hover:after,
.no-touch [app-header] .gnb ul li span:hover:after {
  width: 100%;
}
[app-header] .gnb .menu:first-of-type ul {
  padding-top: 5.25rem;
}
[app-header] .gnb .menu ul {
  padding-top: 4.5rem;
}
[app-header] .gnb .menu ul li.label {
  margin-bottom: 0.75rem;
  display: block;
}
[app-header] .gnb .menu ul li.label a {
  font-size: 1.25rem;
  font-family: 'Helvetica Now', sans-serif;
  font-weight: 700;
  display: inline-block;
}
[app-header] .gnb .menu ul li.label a:after {
  height: 0.125rem;
  bottom: 0rem;
}
[app-header] .gnb .menu ul li {
  overflow: hidden;
}
[app-header] .gnb .menu ul li a,
[app-header] .gnb .menu ul li span {
  transform: translateY(100%);
  display: block;
  transition: transform 1.2s cubic-bezier(0.49, -0.01, 0.07, 1);
  transition-delay: 0s;
}
[app-header] .gnb .menu ul li a {
  font-size: 2.875rem;
  font-family: 'Helvetica-Now-Display-Black', sans-serif;
  position: relative;
  display: inline-block;
}
[app-header] .gnb .menu ul li a:after {
  height: 0.1875rem;
  bottom: 0.625rem;
}
[app-header] .gnb .lang {
  position: relative;
}
[app-header] .gnb .lang.open a i {
  transform: rotate(180deg);
}
[app-header] .gnb .lang.open .lang-popup {
  transform: scale(1);
}
[app-header] .gnb .lang.open .lang-popup ul li {
  opacity: 1;
  transform: translateY(0px);
}
[app-header] .gnb .lang.open .lang-popup li:nth-of-type(1) {
  transition-delay: 0s, 0.2s, 0.2s;
}
[app-header] .gnb .lang.open .lang-popup li:nth-of-type(2) {
  transition-delay: 0s, 0.3s, 0.3s;
}
[app-header] .gnb .lang.open .lang-popup li:nth-of-type(3) {
  transition-delay: 0s, 0.4s, 0.4s;
}
[app-header] .gnb .lang.open .lang-popup li:nth-of-type(4) {
  transition-delay: 0s, 0.5s, 0.5s;
}
[app-header] .gnb .lang.open .lang-popup li:nth-of-type(5) {
  transition-delay: 0s, 0.6s, 0.6s;
}
[app-header] .gnb .lang a {
  margin-top: 9.875rem;
  display: inline-block;
  background-color: transparent;
  color: #1e1e1e;
}
[app-header] .gnb .lang a span {
  display: inline-block;
  vertical-align: middle;
  padding-top: 0rem;
  padding-right: 0.25rem;
  padding-bottom: 0rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
}
[app-header] .gnb .lang a i {
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
}
[app-header] .gnb .lang a i.global-ic {
  width: 1rem;
  height: 1rem;
  background-image: url('/img/common/icon-global.png');
}
[app-header] .gnb .lang a i.dd-ic {
  width: 0.875rem;
  height: 0.875rem;
  background-image: url('/img/common/ic-dropdown.png');
}
[app-header] .gnb .lang .lang-popup {
  position: absolute;
  left: 0rem;
  bottom: 1.75rem;
  right: auto;
  top: auto;
  padding-top: 1.125rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  transform: scale(0);
  background-color: #fff;
  border-radius: 0.625rem;
  transform-origin: left bottom;
  border: 1px solid #ebebeb;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
  transition: transform 0.6s cubic-bezier(0.54, -0.03, 0.06, 0.98);
}
[app-header] .gnb .lang .lang-popup ul li {
  font-size: 0.875rem;
  font-weight: 500;
  color: #888;
  padding: 0.25rem;
  transition-delay: 0s;
  transition: color 0.2s ease, opacity 0.3091s cubic-bezier(0.32, 0.08, 0.24, 1) 0.03s, transform 0.3455s cubic-bezier(0.32, 0.08, 0.24, 1) 0.02s;
  opacity: 0;
  transform: translateY(10px);
}
[app-header] .gnb .lang .lang-popup ul li.on,
.no-touch [app-header] .gnb .lang .lang-popup ul li:hover {
  color: #1e1e1e;
}
[app-header] .gnb .lang .lang-popup ul li:first-of-type {
  padding-top: 0rem;
}
[app-header] .gnb .lang .lang-popup ul li:last-of-type {
  padding-bottom: 0rem;
}
[app-header] .gnb .social {
  display: none;
}
[app-header] .gnb .social ul {
  opacity: 0;
  transition: opacity 0.8s ease;
  transition-delay: 0s;
}
[app-header] .gnb .social ul .lng-menu-li:nth-of-type(2) {
  font-family: 'NotoSans-kr', sans-serif;
}
[app-header] .gnb .social ul .lng-menu-li:nth-of-type(3) {
  font-family: 'Helvetica Now', sans-serif;
}
[app-header] .gnb .social ul .lng-menu-li:nth-of-type(4) {
  font-family: 'NotoSans-jp', sans-serif;
}
[app-header] .gnb .social ul .lng-menu-li:nth-of-type(5) {
  font-family: 'NotoSans-tc', sans-serif;
}
[app-header] .gnb .social ul .lng-menu-li:nth-of-type(6) {
  font-family: 'NotoSans', sans-serif;
}
[app-header] .gnb.open {
  opacity: 1;
  top: 0rem;
  transition-delay: 0s, 0s;
}
[app-header] .gnb.open .inner {
  left: 0rem;
  top: 0%;
  right: auto;
  bottom: auto;
  transition-delay: 0s;
}
[app-header] .gnb.open .close {
  transform: translateY(0);
  transition-delay: 0s,0s;
}
[app-header] .gnb.open .menu ul li a,
[app-header] .gnb.open .menu ul li span {
  transform: translateY(0);
}
[app-header] .gnb.open .social ul {
  opacity: 1;
  transition-delay: 1s;
}
[app-header] .gnb.open .section:after {
  height: 100%;
}
[app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(2) a,
[app-header] .gnb.open .menu:first-of-type ul li span {
  transition-delay: 0.1s;
}
[app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(3) a,
[app-header] .gnb.open .menu:first-of-type ul li span {
  transition-delay: 0.2s;
}
[app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(4) a,
[app-header] .gnb.open .menu:first-of-type ul li span {
  transition-delay: 0.3s;
}
[app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(5) a,
[app-header] .gnb.open .menu:first-of-type ul li span {
  transition-delay: 0.4s;
}
[app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(6) a,
[app-header] .gnb.open .menu:first-of-type ul li span {
  transition-delay: 0.5s;
}
[app-header] .gnb.open .menu:nth-of-type(2) ul li.label a {
  transition-delay: 0.6s;
}
[app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(1) a,
[app-header] .gnb.open .menu:nth-of-type(2) ul li span {
  transition-delay: 0.6s;
}
[app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(2) a,
[app-header] .gnb.open .menu:nth-of-type(2) ul li span {
  transition-delay: 0.7s;
}
[app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(3) a,
[app-header] .gnb.open .menu:nth-of-type(2) ul li span {
  transition-delay: 0.8s;
}
[app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(4) a,
[app-header] .gnb.open .menu:nth-of-type(2) ul li span {
  transition-delay: 0.9s;
}
[app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(5) a,
[app-header] .gnb.open .menu:nth-of-type(2) ul li span {
  transition-delay: 1s;
}
[app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(6) a,
[app-header] .gnb.open .menu:nth-of-type(2) ul li span {
  transition-delay: 1.1s;
}
[app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(7) a,
[app-header] .gnb.open .menu:nth-of-type(2) ul li span {
  transition-delay: 1.2s;
}
[app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(8) a,
[app-header] .gnb.open .menu:nth-of-type(2) ul li span {
  transition-delay: 1.3s;
}
[app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(9) a,
[app-header] .gnb.open .menu:nth-of-type(2) ul li span {
  transition-delay: 1.4s;
}
[app-header].dark header,
[app-header].dark-header header {
  background-color: transparent;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(30, 30, 30, 0));
}
[app-header].dark header .inner .home,
[app-header].dark-header header .inner .home {
  background-image: url('/img/common/header-nc-icon-dark.png');
}
[app-header].dark header .inner .h-tab a span,
[app-header].dark-header header .inner .h-tab a span {
  background-color: #a9a9a9;
}
.no-touch [app-header].dark header .inner .h-tab a:hover,
.no-touch [app-header].dark-header header .inner .h-tab a:hover {
  color: #fff;
}
[app-header].dark header .inner .h-tab a.router-link-active,
[app-header].dark-header header .inner .h-tab a.router-link-active {
  color: #fff;
}
[app-header].dark header .inner .h-tab a.router-link-active span,
[app-header].dark-header header .inner .h-tab a.router-link-active span {
  background-color: #fff;
}
[app-header].dark header .inner .h-btn a.search,
[app-header].dark-header header .inner .h-btn a.search {
  background-image: url('/img/common/header-search-icon-dark.png');
}
[app-header].dark header .inner .h-btn a.menu,
[app-header].dark-header header .inner .h-btn a.menu {
  background-image: url('/img/common/header-menu-icon-dark.png');
}
[app-header].dark .gnb,
[app-header].dark-header .gnb {
  background-color: #000;
  color: #fff;
}
[app-header].dark .gnb .close,
[app-header].dark-header .gnb .close {
  background-image: url('/img/common/ic-close-dark.png');
}
[app-header].dark .gnb li,
[app-header].dark-header .gnb li,
[app-header].dark .gnb a,
[app-header].dark-header .gnb a {
  color: #fff;
}
[app-header].dark .gnb .section:after,
[app-header].dark-header .gnb .section:after {
  background-color: #666;
}
[app-header].dark .gnb .section.social ul li i,
[app-header].dark-header .gnb .section.social ul li i {
  background-image: url('/img/common/ic-out-dark.png');
}
[app-header].dark .gnb ul li a:after,
[app-header].dark-header .gnb ul li a:after,
[app-header].dark .gnb ul li span:after,
[app-header].dark-header .gnb ul li span:after {
  background-color: #fff;
}
[app-header].dark .gnb .lang a i.global-ic,
[app-header].dark-header .gnb .lang a i.global-ic {
  background-image: url('/img/common/icon-global-dark.png');
}
[app-header].dark .gnb .lang a i.dd-ic,
[app-header].dark-header .gnb .lang a i.dd-ic {
  background-image: url('/img/common/ic-dropdown-dark.png');
}
[app-header].dark .gnb .lang .lang-popup,
[app-header].dark-header .gnb .lang .lang-popup {
  background-color: #1e1e1e;
  border: 0;
}
[app-header].dark .gnb .lang .lang-popup ul li.on,
[app-header].dark-header .gnb .lang .lang-popup ul li.on,
.no-touch [app-header].dark .gnb .lang .lang-popup ul li:hover,
.no-touch [app-header].dark-header .gnb .lang .lang-popup ul li:hover {
  color: #fff;
}
[app-header].dark-list header {
  background-color: #000;
}
@media (min-width: 768px) {
  [app-header] header .inner {
    padding-top: 0.875rem;
    padding-right: 2.25rem;
    padding-bottom: 0.875rem;
    padding-left: 2.25rem;
  }
  [app-header] header .inner .h-tab a {
    margin-top: 0rem;
    margin-right: 0.75rem;
    margin-bottom: 0rem;
    margin-left: 0.75rem;
  }
  [app-header] .gnb {
    padding-top: 0rem;
    padding-right: 2.375rem;
    padding-bottom: 3rem;
    padding-left: 2.375rem;
  }
  [app-header] .gnb .close {
    right: 2.25rem;
    top: 1.25rem;
    left: auto;
    bottom: auto;
  }
  [app-header] .gnb .menu ul {
    padding-top: 6rem;
  }
  [app-header] .gnb .menu ul li.label a {
    font-size: 1.375rem;
  }
  [app-header] .gnb .menu ul li a {
    font-size: 3.875rem;
  }
  [app-header] .gnb .lang a {
    margin-top: 12.5rem;
  }
  [app-header] .gnb .lang a span {
    font-size: 1.125rem;
  }
  [app-header] .gnb .lang a i.global-ic {
    width: 1.125rem;
    height: 1.125rem;
  }
  [app-header] .gnb .lang a i.dd-ic {
    width: 0.875rem;
    height: 0.875rem;
  }
  [app-header] .gnb .lang .lang-popup {
    left: 0rem;
    bottom: 2rem;
    right: auto;
    top: auto;
    padding-top: 1.25rem;
    padding-right: 1.375rem;
    padding-bottom: 1.375rem;
    padding-left: 1.375rem;
  }
  [app-header] .gnb .lang .lang-popup ul li {
    font-size: 1.125rem;
  }
  [app-header].dark-list header {
    background-color: transparent;
  }
}
@media (min-width: 1024px) {
  [app-header] header .inner .h-tab a {
    margin-top: 0rem;
    margin-right: 1.125rem;
    margin-bottom: 0rem;
    margin-left: 1.125rem;
  }
  [app-header] header .inner .h-btn a {
    margin-left: 1.25rem;
  }
  [app-header] .gnb {
    padding-top: 0rem;
    padding-right: 2.25rem;
    padding-bottom: 3rem;
    padding-left: 2.25rem;
  }
  [app-header] .gnb .lang .lang-popup {
    left: 0rem;
    bottom: 2.25rem;
    right: auto;
    top: auto;
  }
}
@media (min-width: 1024px) and (min-width: 1024px) and (max-width: 1440px) and (max-height: 900px) {
  [app-header] .gnb {
    min-height: calc(var(--vh, 1vh) * 100);
    padding: 0;
  }
  [app-header] .gnb .section.menu,
  [app-header] .gnb .section.social {
    min-height: 35rem;
  }
  [app-header] .gnb .lang {
    display: none;
  }
  [app-header] .gnb .inner {
    width: 100%;
    height: 100%;
  }
  [app-header] .gnb .menu ul li.label a {
    font-size: 1.125rem;
  }
  [app-header] .gnb .menu ul li a {
    font-size: 2.5rem;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(1) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(1) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(2) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(2) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.1s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(3) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(3) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.2s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(4) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(4) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.3s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(5) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(5) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.4s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(6) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(6) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.5s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(7) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(7) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.6s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(8) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(8) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.7s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(9) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(9) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.8s;
  }
  [app-header] .gnb.open:nth-of-type(2) ul li.label a {
    transition-delay: 0s !important;
  }
  [app-header] .gnb .close {
    right: 2.25rem;
    top: 1.25rem;
    left: auto;
    bottom: auto;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
  }
  [app-header] .gnb .section {
    width: calc(100% / 3);
    height: 100%;
    display: inline-block;
    vertical-align: top;
    padding-top: 3.75rem;
    padding-right: 2.25rem;
    padding-bottom: 3.75rem;
    padding-left: 2.25rem;
    box-sizing: border-box;
    min-height: 50.3125rem;
    position: relative;
  }
  [app-header] .gnb .section:after {
    width: 0.0625rem;
    height: 0%;
    background-color: #c9c9c9;
    content: '';
    position: absolute;
    right: 0rem;
    top: 0rem;
    left: auto;
    bottom: auto;
    transition: height 2s cubic-bezier(0.49, -0.01, 0.07, 1);
  }
  [app-header] .gnb .section.social:after {
    background-color: transparent;
  }
  [app-header] .gnb .section:first-of-type {
    padding-left: 3rem;
  }
  [app-header] .gnb .section:first-of-type ul {
    padding: 0;
  }
  [app-header] .gnb .section ul {
    padding: 0rem;
  }
  [app-header] .gnb .social {
    display: inline-block;
  }
  [app-header] .gnb .social ul:last-of-type {
    padding-top: 4.75rem;
  }
  [app-header] .gnb .social ul li.label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.875rem;
  }
  [app-header] .gnb .social ul li {
    padding-top: 0.25rem;
    padding-right: 0rem;
    padding-bottom: 0.25rem;
    padding-left: 0rem;
    font-size: 0.875rem;
  }
  [app-header] .gnb .social ul li i {
    background-image: url('/img/common/ic-out.png');
    width: 0.875rem;
    height: 0.875rem;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: 0.375rem;
    vertical-align: middle;
  }
}
@media (min-width: 1440px) {
  [app-header] header {
    height: 5rem;
  }
  [app-header] header .inner {
    padding-top: 1.25rem;
    padding-right: 3rem;
    padding-bottom: 1.25rem;
    padding-left: 3rem;
  }
  [app-header] header .inner .home {
    width: 3.25rem;
    height: 1.75rem;
  }
  [app-header] header .inner .h-tab a {
    margin-top: 0rem;
    margin-right: 1.5rem;
    margin-bottom: 0rem;
    margin-left: 1.5rem;
    font-size: 1.25rem;
    padding-bottom: 0.3125rem;
  }
  [app-header] header .inner .h-tab a span {
    height: 0.1875rem;
  }
  [app-header] header .inner .h-btn {
    height: 1.75rem;
  }
  [app-header] header .inner .h-btn a {
    width: 1.75rem;
    height: 1.75rem;
    margin-left: 1.5rem;
  }
  [app-header] .gnb {
    min-height: calc(var(--vh, 1vh) * 100);
    padding: 0;
  }
  [app-header] .gnb .inner {
    width: 100%;
    height: 100%;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(1) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(1) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(2) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(2) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.1s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(3) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(3) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.2s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(4) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(4) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.3s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(5) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(5) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.4s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(6) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(6) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.5s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(7) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(7) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.6s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(8) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(8) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.7s;
  }
  [app-header] .gnb.open .menu:first-of-type ul li:nth-of-type(9) a,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li:nth-of-type(9) a,
  [app-header] .gnb.open .menu:first-of-type ul li span,
  [app-header] .gnb.open .menu:nth-of-type(2) ul li span {
    transition-delay: 0.8s;
  }
  [app-header] .gnb.open .menu:nth-of-type(2) ul li.label a {
    transition-delay: 0s;
  }
  [app-header] .gnb .close {
    right: 3rem;
    top: 1.625rem;
    left: auto;
    bottom: auto;
    width: 1.75rem;
    height: 1.75rem;
    position: absolute;
  }
  [app-header] .gnb .section {
    width: calc(100% / 3);
    height: 100%;
    display: inline-block;
    vertical-align: top;
    padding-top: 3.75rem;
    padding-right: 2.25rem;
    padding-bottom: 3.75rem;
    padding-left: 2.25rem;
    box-sizing: border-box;
    min-height: 50.3125rem;
    position: relative;
  }
  [app-header] .gnb .section:after {
    width: 0.0625rem;
    height: 0%;
    background-color: #c9c9c9;
    content: '';
    position: absolute;
    right: 0rem;
    top: 0rem;
    left: auto;
    bottom: auto;
    transition: height 2s cubic-bezier(0.49, -0.01, 0.07, 1);
  }
  [app-header] .gnb .section.social:after {
    background-color: transparent;
  }
  [app-header] .gnb .section:first-of-type {
    padding-left: 3rem;
  }
  [app-header] .gnb .section:first-of-type ul {
    padding: 0;
  }
  [app-header] .gnb .section ul {
    padding: 0rem;
  }
  [app-header] .gnb .social {
    display: inline-block;
  }
  [app-header] .gnb .social ul:last-of-type {
    padding-top: 6rem;
  }
  [app-header] .gnb .social ul li.label {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.875rem;
  }
  [app-header] .gnb .social ul li {
    padding-top: 0.25rem;
    padding-right: 0rem;
    padding-bottom: 0.25rem;
    padding-left: 0rem;
    font-size: 1.125rem;
  }
  [app-header] .gnb .social ul li i {
    background-image: url('/img/common/ic-out.png');
    width: 0.875rem;
    height: 0.875rem;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    margin-left: 0.375rem;
    vertical-align: middle;
  }
}
@media (min-width: 1921px) {
  [app-header] header {
    height: 6rem;
  }
  [app-header] header .inner {
    padding-top: 1.375rem;
    padding-right: 3.75rem;
    padding-bottom: 1.375rem;
    padding-left: 3.75rem;
  }
  [app-header] header .inner .home {
    width: 4.0625rem;
    height: 2.1875rem;
  }
  [app-header] header .inner .h-tab a {
    margin-top: 0rem;
    margin-right: 1.84375rem;
    margin-bottom: 0rem;
    margin-left: 1.84375rem;
    font-size: 1.5625rem;
    padding-bottom: 0.375rem;
  }
  [app-header] header .inner .h-tab a span {
    height: 0.25rem;
  }
  [app-header] header .inner .h-btn {
    height: 2rem;
  }
  [app-header] header .inner .h-btn a {
    width: 2rem;
    height: 2rem;
    margin-left: 2rem;
  }
  [app-header] .gnb .close {
    right: 3.75rem;
    top: 2rem;
    left: auto;
    bottom: auto;
    width: 2rem;
    height: 2rem;
  }
  [app-header] .gnb .section {
    padding-top: 5.125rem;
    padding-right: 3rem;
    padding-bottom: 5.125rem;
    padding-left: 3rem;
    min-height: 66.625rem;
  }
  [app-header] .gnb .section:first-of-type {
    padding-left: 3.75rem;
  }
  [app-header] .gnb .menu ul li.label {
    margin-bottom: 1.125rem;
  }
  [app-header] .gnb .menu ul li.label a {
    font-size: 1.75rem;
  }
  [app-header] .gnb .menu ul li a {
    font-size: 5.125rem;
  }
  [app-header] .gnb .social ul:last-of-type {
    padding-top: 7.5rem;
  }
  [app-header] .gnb .social ul li {
    padding-top: 0.3125rem;
    padding-right: 0rem;
    padding-bottom: 0.3125rem;
    padding-left: 0rem;
    font-size: 1.5rem;
  }
  [app-header] .gnb .social ul li.label {
    font-size: 1.125rem;
    margin-bottom: 1.1875rem;
  }
  [app-header] .gnb .social ul li i {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
}
