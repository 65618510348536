.t {
  transform: none;
}
[footer] {
  position: relative;
  background-color: #fff;
}
[footer].viewer,
[footer].search {
  display: none;
}
[footer] .inner {
  max-width: 167.5rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  padding-top: 2.3125rem;
  padding-right: 1.125rem;
  padding-bottom: 1.5rem;
  padding-left: 1.125rem;
  box-sizing: border-box;
}
[footer] .go-top {
  width: 2.75rem;
  height: 3.4375rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 7.5rem;
  background-color: transparent;
  overflow: hidden;
}
[footer] .go-top span {
  width: 100%;
  height: 100%;
  display: block;
  transform: translateY(0%);
  background-image: url('/img/common/go-top-arrow.png');
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.6s cubic-bezier(0.4, -0.03, 0, 1.03);
}
.no-touch [footer] .go-top:hover span {
  transform: translateY(-100%);
}
[footer] .map {
  margin-bottom: 11.25rem;
}
[footer] .map a {
  padding-top: 1.25rem;
  padding-right: 0rem;
  padding-bottom: 1.25rem;
  padding-left: 0rem;
  border-bottom: 1px solid #1e1e1e;
  display: block;
  width: 100%;
  background-color: transparent;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 500;
  color: #1e1e1e;
  transition: color 0.2s ease;
}
[footer] .map a i {
  width: 1rem;
  height: 1rem;
  transform: rotate(0deg);
  background-image: url('/img/common/ic-more.png');
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  float: right;
  transition: transform 0.4s ease;
}
.no-touch [footer] .map a:hover {
  color: #666;
}
[footer] .map .menus {
  overflow: hidden;
  height: auto;
  max-height: 0rem;
  transition: max-height 0.7s cubic-bezier(0, 0, 0.3, 1.02);
}
[footer] .map .menus ul {
  padding-top: 0.75rem;
}
[footer] .map .menus ul li {
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-right: 0rem;
  padding-bottom: 0.5rem;
  padding-left: 0rem;
  font-size: 0.875rem;
  color: #1e1e1e;
}
.no-touch [footer] .map .menus ul li:hover {
  font-weight: 700;
}
[footer] .map .menus ul li a {
  display: block;
  padding: 0;
  border: 0;
}
[footer] .map .menus ul li:last-of-type {
  padding-bottom: 0rem;
}
[footer] .map .global a {
  margin-top: 1.75rem;
}
[footer] .map .global .menus ul li {
  color: #666;
  cursor: pointer;
}
[footer] .map .global .menus ul li.on {
  font-weight: 500;
  color: #1e1e1e;
}
[footer] .map .active a i {
  transform: rotate(-135deg);
}
[footer] .map .active .menus {
  max-height: 31.25rem;
  transition: max-height 1.2s cubic-bezier(0.32, 0.08, 0.24, 1) 0.02s;
}
[footer] .logo {
  width: 100%;
  margin-bottom: 3.75rem;
  display: block;
  position: relative;
  height: auto;
}
[footer] .logo .dark {
  display: none;
}
[footer] .logo > img {
  width: 100%;
  display: block;
}
[footer] .logo p {
  height: 100%;
  display: block;
  position: absolute;
  left: 25%;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: auto;
}
[footer] .logo p span {
  height: 100%;
  width: 102%;
  position: absolute;
  left: -0.0625rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  background-image: url('/img/footer/mask-white.png');
  background-size: cover;
  background-repeat: no-repeat;
}
[footer] .logo p video {
  height: 100%;
  position: relative;
}
[footer] .info-share {
  text-align: center;
}
[footer] .info-share ul {
  margin-bottom: 3.75rem;
}
[footer] .info-share ul.ir {
  text-indent: 0;
}
[footer] .info-share ul li {
  display: inline-block;
  margin-top: 0rem;
  margin-right: 0.625rem;
  margin-bottom: 0rem;
  margin-left: 0.625rem;
}
[footer] .info-share ul li:nth-of-type(1) a {
  background-image: url('/img/common/sns-fb.svg');
}
[footer] .info-share ul li:nth-of-type(2) a {
  background-image: url('/img/common/sns-instagram.svg');
}
[footer] .info-share ul li:nth-of-type(3) a {
  background-image: url('/img/common/sns-youtube.svg');
}
[footer] .info-share ul li:nth-of-type(4) a {
  background-image: url('/img/common/sns-linkedin.svg');
}
[footer] .info-share ul li:nth-of-type(5) a {
  background-image: url('/img/common/sns-tictok.svg');
}
[footer] .info-share ul li a {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}
.no-touch [footer] .info-share ul li a:hover {
  opacity: 1;
}
[footer] .info-share ul li a img {
  width: 100%;
  display: block;
}
[footer] .info-share .copy {
  font-size: 0.625rem;
  line-height: 0.11875rem;
  line-height: 1.9;
  color: #333;
}
[footer] .info-share .copy a {
  display: inline-block;
  transition: color 0.2s ease;
}
.no-touch [footer] .info-share .copy a:hover {
  color: #000;
}
[footer].dark {
  background-color: #000;
}
[footer].dark .go-top span {
  background-image: url('/img/common/go-top-arrow-dark.png');
}
[footer].dark .map a {
  color: #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
[footer].dark .map a i {
  background-image: url('/img/common/ic-more-dark.png');
}
.no-touch [footer].dark .map a:hover {
  color: #888;
}
[footer].dark .map .menus ul li {
  color: #ebebeb;
}
[footer].dark .map .global .menus ul li {
  color: #888;
}
[footer].dark .map .global .menus ul li.on {
  color: #ebebeb;
}
[footer].dark .logo p span {
  background-image: url('/img/footer/mask-dark.png');
}
[footer].dark .logo .light {
  display: none;
}
[footer].dark .logo .dark {
  display: block;
}
[footer].dark .info-share ul li:nth-of-type(1) a {
  background-image: url('/img/common/sns-fb-dark.svg');
}
[footer].dark .info-share ul li:nth-of-type(2) a {
  background-image: url('/img/common/sns-instagram-dark.svg');
}
[footer].dark .info-share ul li:nth-of-type(3) a {
  background-image: url('/img/common/sns-youtube-dark.svg');
}
[footer].dark .info-share ul li:nth-of-type(4) a {
  background-image: url('/img/common/sns-linkedin-dark.svg');
}
[footer].dark .info-share ul li:nth-of-type(5) a {
  background-image: url('/img/common/sns-tictok-dark.svg');
}
[footer].dark .info-share .copy {
  color: #888;
}
.no-touch [footer].dark .info-share .copy a:hover {
  color: #ddd;
}
@media (min-width: 768px) {
  [footer] .inner {
    padding-top: 2.25rem;
    padding-right: 2.25rem;
    padding-bottom: 1.5rem;
    padding-left: 2.25rem;
  }
  [footer] .map .global a {
    margin-top: 2.5rem;
  }
  [footer] .logo {
    margin-bottom: 4.5rem;
  }
  [footer] .info-share ul {
    margin-bottom: 4.5rem;
  }
}
@media (min-width: 1024px) {
  [footer] .inner {
    padding-top: 2.75rem;
    padding-right: 2.25rem;
    padding-bottom: 1.75rem;
    padding-left: 2.25rem;
  }
  [footer] .go-top {
    position: absolute;
    margin: 0rem;
    right: 2.25rem;
    top: 2rem;
    left: auto;
    bottom: auto;
  }
  [footer] .map {
    position: absolute;
    left: 2.25rem;
    top: 2.75rem;
    right: auto;
    bottom: auto;
  }
  [footer] .map a {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 1rem;
    padding-left: 0rem;
    font-size: 0.75rem;
  }
  [footer] .map a i {
    width: 0.875rem;
    height: 0.875rem;
    margin-top: 0.125rem;
  }
  [footer] .map .family,
  [footer] .map .global {
    display: inline-block;
    width: 11.625rem;
    vertical-align: top;
    position: relative;
    float: right;
  }
  [footer] .map .family {
    margin-left: 3.25rem;
  }
  [footer] .map .global a {
    margin-top: 0rem;
  }
  [footer] .map .menus {
    position: absolute;
    left: 0rem;
    top: 2.3125rem;
    right: auto;
    bottom: auto;
  }
  [footer] .map .menus ul li {
    padding-top: 0.25rem;
    padding-right: 0rem;
    padding-bottom: 0.25rem;
    padding-left: 0rem;
    font-size: 0.75rem;
  }
  [footer] .logo {
    width: 37.875rem;
    margin-bottom: 0rem;
    margin-top: 16.25rem;
  }
  #app.en [footer] .logo,
  #app.jp [footer] .logo {
    margin-top: 21.25rem;
  }
  #app.tw [footer] .logo {
    margin-top: 20rem;
  }
  #app.vn [footer] .logo {
    margin-top: 17.5rem;
  }
  [footer] .info-share {
    position: absolute;
    right: 2.25rem;
    bottom: 1.75rem;
    left: auto;
    top: auto;
    text-align: right;
  }
  [footer] .info-share ul {
    margin-bottom: 1.375rem;
  }
  [footer] .info-share ul li {
    margin-top: 0rem;
    margin-right: 0.5625rem;
    margin-bottom: 0rem;
    margin-left: 0.5625rem;
  }
  [footer] .info-share ul li:last-of-type {
    margin-right: 0rem;
  }
  [footer] .info-share ul li a {
    width: 1.0625rem;
    height: 1.0625rem;
  }
  [footer] .info-share .copy {
    font-size: 0.5rem;
    line-height: 0.1125rem;
    line-height: 1.8;
  }
}
@media (min-width: 1440px) {
  [footer] .inner {
    padding-top: 3.875rem;
    padding-right: 3rem;
    padding-bottom: 2.25rem;
    padding-left: 3rem;
  }
  [footer] .go-top {
    right: 3rem;
    top: 2.8125rem;
    left: auto;
    bottom: auto;
    width: 3.75rem;
    height: 4.6875rem;
  }
  [footer] .map {
    left: 3rem;
    top: 3.875rem;
    right: auto;
    bottom: auto;
  }
  [footer] .map a {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 1.5rem;
    padding-left: 0rem;
    font-size: 1rem;
  }
  [footer] .map a i {
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.125rem;
  }
  [footer] .map .family,
  [footer] .map .global {
    width: 16.125rem;
  }
  [footer] .map .family {
    margin-left: 4.5rem;
  }
  [footer] .map .menus {
    left: 0rem;
    top: 3.3125rem;
    right: auto;
    bottom: auto;
  }
  [footer] .map .menus ul {
    padding-top: 1.125rem;
  }
  [footer] .map .menus ul li {
    padding-top: 0.375rem;
    padding-right: 0rem;
    padding-bottom: 0.375rem;
    padding-left: 0rem;
    font-size: 1rem;
  }
  [footer] .logo {
    width: 53.3125rem;
    margin-top: 22.875rem;
  }
  [footer] .logo p span {
    height: 102%;
    width: 102%;
    left: -0.0625rem;
    top: -0.0625rem;
    right: auto;
    bottom: auto;
  }
  #app.en [footer] .logo,
  #app.jp [footer] .logo {
    margin-top: 30rem;
  }
  #app.tw [footer] .logo {
    margin-top: 28.75rem;
  }
  #app.vn [footer] .logo {
    margin-top: 27.625rem;
  }
  [footer] .info-share {
    right: 3.25rem;
    bottom: 2.25rem;
    left: auto;
    top: auto;
  }
  [footer] .info-share ul {
    margin-bottom: 2.125rem;
  }
  [footer] .info-share ul li {
    margin-top: 0rem;
    margin-right: 0.8125rem;
    margin-bottom: 0rem;
    margin-left: 0.8125rem;
  }
  [footer] .info-share ul li a {
    width: 1.5rem;
    height: 1.5rem;
  }
  [footer] .info-share .copy {
    font-size: 0.625rem;
    line-height: 0.10625rem;
    line-height: 1.7;
  }
}
@media (min-width: 1600px) {
  [footer] .inner {
    padding-top: 5.1875rem;
    padding-right: 3.75rem;
    padding-bottom: 3rem;
    padding-left: 3.75rem;
  }
  [footer] .go-top {
    right: 3.75rem;
    top: 3.75rem;
    left: auto;
    bottom: auto;
    width: 5rem;
    height: 6.25rem;
  }
  [footer] .map {
    left: 3.75rem;
    top: 5.1875rem;
    right: auto;
    bottom: auto;
  }
  [footer] .map a {
    padding-top: 0rem;
    padding-right: 0rem;
    padding-bottom: 1.875rem;
    padding-left: 0rem;
    font-size: 1.3125rem;
  }
  [footer] .map a i {
    width: 1.75rem;
    height: 1.75rem;
    margin-top: 0rem;
  }
  [footer] .map .family,
  [footer] .map .global {
    width: 21.5rem;
  }
  [footer] .map .family {
    margin-left: 6rem;
  }
  [footer] .map .menus {
    left: 0rem;
    top: 4.1875rem;
    right: auto;
    bottom: auto;
  }
  [footer] .map .menus ul {
    padding-top: 1.5rem;
  }
  [footer] .map .menus ul li {
    padding-top: 0.5rem;
    padding-right: 0rem;
    padding-bottom: 0.5rem;
    padding-left: 0rem;
    font-size: 1.3125rem;
  }
  [footer] .logo {
    width: 71.0625rem;
    margin-top: 30.4375rem;
  }
  #app.en [footer] .logo,
  #app.jp [footer] .logo {
    margin-top: 39.375rem;
  }
  #app.tw [footer] .logo {
    margin-top: 35rem;
  }
  #app.vn [footer] .logo {
    margin-top: 31.25rem;
  }
  [footer] .info-share {
    right: 3.75rem;
    bottom: 3rem;
    left: auto;
    top: auto;
  }
  [footer] .info-share ul {
    margin-bottom: 2.875rem;
  }
  [footer] .info-share ul li {
    margin-top: 0rem;
    margin-right: 1.0625rem;
    margin-bottom: 0rem;
    margin-left: 1.0625rem;
  }
  [footer] .info-share ul li a {
    width: 2rem;
    height: 2rem;
  }
  [footer] .info-share .copy {
    font-size: 0.875rem;
    line-height: 0.10625rem;
    line-height: 1.7;
  }
}
