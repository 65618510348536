@import "vars";

@media (max-width: @screen-ms-max) {//359
  .hidden-ms-down { display: none !important; }
}

@media (min-width: @screen-mm-min) { //360
  .hidden-mm-up { display: none !important; }
}

@media (max-width: @screen-mm-max) {//413
  .hidden-mm-down { display: none !important; }
}

@media (min-width: @screen-ml-min) { //414
  .hidden-ml-up { display: none !important; }
}

@media (max-width: @screen-ml-max) { // 767
  .hidden-ml-down { display: none !important; }
}

@media (min-width: @screen-tp-min) { //768
  .hidden-tp-up { display: none !important; }
}

@media (max-width: @screen-tp-max) { //1023
  .hidden-tp-down { display: none !important; }
}

@media (min-width: @screen-tl-min) { //1024
  .hidden-tl-up { display: none !important; }
}

@media (max-width: @screen-tl-max) { //1279
  .hidden-tl-down { display: none !important; }
}

@media (min-width: @screen-ds-min) {//1280
  .hidden-ds-up { display: none !important; }
}

@media (max-width: @screen-ds-max) {//1439
  .hidden-ds-down { display: none !important; }
}

@media (min-width: @screen-dm-min) {//1440
  .hidden-dm-up { display: none !important; }
}

@media (max-width: @screen-dm-max) {//1799
  .hidden-dm-down { display: none !important; }
}

@media (min-width: @screen-dl-min) {//1920
  .hidden-dl-up { display: none !important; }
}
