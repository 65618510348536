@import "proj";

[play-viewer] {.rel; .w(100%); .fvh(); overflow: hidden; .bgc(#1f1e1e);
  .viewer{
    .info .text, .info a, .close, .page-move, .remote,
    .controls a,.video-controls{transition:opacity 1s ease;}
  }
  .viewer{.rel;
    .info{.abs; .wf; .lb(0,0); .-box;
      &.hide{
        .inner{.t-y(100%);}
      }
      .inner{.p(74,18,24,15); .t-y(0); transition:transform 1s ease;}
      .text{width:calc(100% - 83px); .ib; .rel; .w(50%);
        p:first-of-type{.fs(10); .medium; .pb(8);}
        p+p{.fs(13,1.5,-0.25); .medium;}
      }
      a{.bgc(#fff); .br(20); .p(8,14); .abs; .rb(15,24); .fs(0);
        .no-touch &:hover{
          i{transform: rotate(180deg);}
        }
        span{.ib; .c(#1c1c1c); .fs(14); .ls(-0.3);  .medium; .vam;
          #app:not(.ko) & {.fs(13);}
        }
        i{.wh(16); .ml(2); .ib; .bg('/img/common/ic-black-more.png'); .contain; .no-repeat; .vam; transition:transform .4s ease;
          #app:not(.ko) &{.wh(15);}
        }
      }
    }
    .close{.abs; .rt(18,30); .wh(20); .contain; .no-repeat; .bg('/img/common/ic-white-close.png'); transition:opacity .3s ease;
      .no-touch &:hover{.o(0.6);}
    }
    .page-move{.abs; .rb(15,110); .o(1);
      &.full{.o(0);}
      a{.block; .wh(30); .contain; .no-repeat; cursor:pointer; transition:opacity .3s ease;
        .no-touch &:hover{.o(0.6);}
        &.prev{.mb(12); .bg('/img/common/btn-contents-previous-prev.png');}
        &.next{.bg('/img/common/btn-contents-previous-next.png');}
        &.disabled { .o(.3); cursor: not-allowed; }
      }
    }
  }
  .more-info{  .abs; .lt(0,-100%); .f; transition:top 0s ease; transition-delay: 1s;
    .inner{background-color: rgba(0, 0, 0, 0.8); .p(30,18,42); .-box; .o(0); transition:opacity 1s ease;
      .more-close{.wh(20); .mb(30); .ib; .contain; .no-repeat; .bg('/img/common/ic-backward-dark.png'); transition:opacity .3s ease;
        .no-touch &:hover{.o(0.6);}
      }
      [sns-share]{.wh(24); .abs; .rt(18,30);
        a.btn{.bg('/img/common/sns-share-square-dark.png'); .no-repeat; .contain;}
        .share-popup{.t(34);}
      }
      .depth{.mb(18); .fs(12); .medium;}
      .title{.mb(40); .fs(18,1.5,-0.3); .medium;}
      .content{.fs(16,1.5,-0.25); .c(#c9c9c9);}
      .link{.abs; .lb(18,42); .w(90%);
        span{.fs(14,1.5,-0.25); .medium; .vam; .ib;}
        i{.wh(16); .ib; .ml(5); .vam; .contain; .no-repeat; .bg('/img/common/ic-white-landing.png');}
      }
    }
  }
  &.more-open{
    .viewer{
      .close{.o(0);}
    }
    .more-info{.t(0); transition-delay: 0s;
      .inner{.o(1);}
    }
  }
  &.play-wide{
    .viewer,
    .more-info .inner{.f;}
  }
  &.play-shorts, &.play-shortsWide{
    .viewer,
    .more-info .inner{.hf; .max-w(100%); aspect-ratio: 9/16; .mh-c; .rel;}
    .viewer{
      .info{background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 23%, rgba(0, 0, 0, 0));}
    }
    &.more-open{
      .viewer{
        .info .text, .info a, .page-move, .remote,
        .controls a,.video-controls{.o(0);}
        .video-controls{z-index:0;}
      }
      .more-info{.t(0); transition-delay: 0s;
        .inner{.o(1);}
      }
    }
  }
  &.play-shortsWide { [cdn-video], img { object-fit: contain; }}
  @media (max-width: @screen-ml-max) and (max-height: 515px) {
    &.play-wide {
      &.more-open{
        .more-info .inner{.t-x(0);}
      }
      .viewer {
        .info {pointer-events: none;
          .inner {.p(95,48,60);}
          a {.rb(42, 60); .br(32); pointer-events: fill;}
        }
        .close {.rt(48, 42);}
        .page-move {.rb(42, 140);}
      }
      .more-info {
        .inner { .p(40, 0, 0, 48); .wf;
          [sns-share] {.rt(48, 43);}
          .depth{.mb(10); .fs(12);}
          .title{.mb(26); .fs(16,1.5,-0.3);}
          .content{.mb(20); .fs(14,1.5,-0.25);}
          .link{.rel; .lb;
            span{.fs(12,1.5,-0.25);}
            i{.ml(6); .wh(14);}
          }
        }
      }
    }
  }
}
@media (min-width: @screen-tp-min) {
  [play-viewer] {
    .viewer{
      .info{
        .inner{.p(74,26,60);}
        .text{.w(60%);
          p:first-of-type{.fs(15); .pb(12);}
          p+p{.fs(19,1.47,-0.25);}
        }
        a{.br(26); .p(12,19); .rb(25,60);
          span{.fs(19);
           #app:not(.ko) & {.fs(18);}
          }
          i{.wh(23); .ml(4);
            #app:not(.ko) &{.wh(22);}
          }
        }
      }
      .close{.fix; .rt(36,24); .wh(28);}
      .page-move{.abs; .rb(-60,170);
        a{.wh(40);
          &.prev{.mb(14);}
        }
      }
    }
    .more-info{
      .inner{ .p(53,26,0);
        .more-close{.wh(30); .mb(75); }
        [sns-share]{.wh(32); .rt(26,52);
          .share-popup{.t(36);}
        }
        .depth{.mb(23); .fs(15); }
        .title{.mb(50); .fs(22,1.5,-0.19); }
        .content{.mb(64); .fs(20,1.5,-0.25);}
        .link{.rel; .lb;
          span{ .fs(18,1.5,-0.25);}
          i{.wh(23); .ml(6);}
        }
      }
    }
    &.play-wide{
      .viewer{
        .info {pointer-events: none;
          .inner {.p(36);
            .text{.w(60%);
              p:first-of-type{.fs(10); .pb(8);}
              p+p{.fs(13,1.5,-0.25);}
            }
          }
          a{.br(20); .p(8,14); .rb(36,36); pointer-events: fill;
            span{.fs(14);
              #app:not(.ko) & {.fs(14);}
            }
            i{.wh(16); .ml(4);
              #app:not(.ko) &{.wh(17);}
            }
          }
        }
        .close{.rt(36,30); .wh(24);}
        .page-move{.abs; .rb(36,118);
          a{.wh(30);
            &.prev{.mb(12);}
          }
        }
      }
      .more-info{
        .inner{ .p(32,34,0);
          .more-close{.wh(24); .mb(58); }
          [sns-share]{.wh(28); .rt(36,30);
            .share-popup{.t(40);}
          }
          .depth{.mb(18); .fs(12); }
          .title,.content{.w(60%);}
          .title{.mb(40); .fs(18,1.5,-0.25); }
          .content{.mb(50); .fs(16,1.5,-0.25);}
          .link{.rel; .lb;
            span{ .fs(14,1.5,-0.25);}
            i{.wh(18); .ml(4);}
          }
        }
      }
    }
    &.play-shortsWide {
      .viewer,
      .more-info .inner {aspect-ratio: auto; }
      .close{.rt(26,54); .wh(28);}
      [cdn-video] { object-fit: contain; }
    }
    @media (min-width: @screen-tp-min) and (max-height: 768px) {
      &.play-shorts, &.play-shortsWide {
        .more-info .inner{.abs;}
      }
      .viewer{
        .info{
          .inner{.p(57,20,48);}
          .text{.w(50%);
            p:first-of-type{.fs(12); .pb(10);}
            p+p{.fs(15,1.47,-0.25);}
          }
          a{.br(20); .p(9,15); .rb(20,48);
            span{.fs(15); .ls(-0.32);
              #app:not(.ko) & {.fs(14);}
            }
            i{.wh(17); .ml(2);}
          }
        }
        .close{.wh(24); .rt(48,43);}
        .page-move{.fix; .rb(42,96);
          a{.wh(40);
            &.prev{.mb(14);}
          }
        }
      }
      .more-info{
        .inner{ .p(43,60,0,48); aspect-ratio: inherit; .w(50%); .m; .abs; .rt(0,0); .t-x(100%); transition:opacity 1s ease, transform 1s ease;
          .more-close{.wh(24); .mb(56); transform:rotate(180deg);}
          [sns-share]{.wh(24); .rt(48,43);}
          .depth{.mb(18); .fs(12); }
          .title,.content{.w(100%);}
          .title{.mb(40); .fs(18,1.5,-0.25); }
          .content{.mb(50); .fs(16,1.5,-0.25);}
          .link{
            span{ .fs(14,1.5,-0.25);}
            i{.wh(18); .ml(6);}
          }
        }
      }
      &.more-open{
        .more-info .inner{.t-x(0);}
      }
      &.play-wide {
        .viewer {
          .info {
            .inner {.p(95, 48,60);}
            a {.rb(42, 60); .br(32);}
          }
          .close {.rt(48, 42);}
          .page-move {.rb(42, 140);}
        }
        .more-info {
          .inner { .p(43, 0, 0, 60); .wf;
            [sns-share] {.rt(48, 43);}
          }
        }
      }
    }
  }
}
@media (min-width: @screen-tl-min) {
  [play-viewer] {
    .viewer{
      .info{
        .inner{.p(97,34,80);}
        .text{
          p:first-of-type{.fs(20); .pb(16);}
          p+p{.fs(25,1.47,-0.42);}
        }
        a{.br(34); .p(15,25); .rb(60,82);
          span{.fs(25); .ls(-0.54);
            #app:not(.ko) & {.fs(24);}
          }
          i{.wh(28); .ml(4);
            #app:not(.ko) &{.wh(27);}
          }
        }
      }
      .close{.wh(28);}
      .page-move{.rb(-92,220);
        a{.wh(60);
          &.prev{.mb(21);}
        }
      }
    }
    .more-info{
      .inner{ .p(71,36,0);
        .more-close{.wh(40); .mb(100); }
        [sns-share]{.wh(43); .rt(35,69);
          .share-popup{.t(52);}
        }
        .depth{.mb(30); .fs(20); }
        .title{.mb(68); .fs(30,1.5,-0.25); }
        .content{.mb(84); .fs(27,1.48,-0.25);}
        .link{
          span{ .fs(24,1.47,-0.25);}
          i{.wh(30); .ml(8);}
        }
      }
    }
    &.play-wide{
      .viewer{
        .info {
          .inner {.p(48,36);
            .text{
              p:first-of-type{.fs(12); .pb(14);}
              p+p{.fs(16,1.5,-0.25);}
            }
          }
          a {.br(20); .p(8, 18, 10); .rb(36, 48);
            span {.fs(18);
              #app:not(.ko) & {.fs(17);}
            }
            i {.wh(20); .ml(4);
              #app:not(.ko) &{.wh(20);}
            }
          }
        }
        .close{.rt(36,30); .wh(28);}
        .page-move{.rb(36,144);
          a{.wh(40);
            &.prev{.mb(14);}
          }
        }
      }
      .more-info{
        .inner{.w(70%); .p(30,0,0,60); .abs; .rt(0,0); .t-x(100%); .o(1); transition:opacity 1s ease, transform 1s ease;
          .more-close{.wh(28); .mb(56); transform:rotate(180deg);}
          [sns-share]{.wh(28); .rt(48,30);}
        }
      }
      &.more-open{
        .more-info .inner{.t-x(1);}
      }
    }
    &.play-shortsWide {
      .viewer .page-move{.rb(20,120);
        a{.wh(40);
          &.prev{.mb(14);}
        }
      }
    }
    @media (min-width: @screen-tl-min) and (max-height: 1024px) {
      &.play-shorts, &.play-shortsWide {
        .more-info .inner{.abs;}
      }
      .viewer{
        .info{
          .inner{.p(57,20,48);}
          .text{
            p:first-of-type{.fs(12); .pb(10); }
            p+p{.fs(15,1.47,-0.25);}
          }
          a{.br(20); .p(9,15); .rb(20,48);
            span{.fs(15); .ls(-0.32);
              #app:not(.ko) & {.fs(14);}
            }
            i{.wh(17); .ml(2);
              #app:not(.ko) &{.wh(18);}
            }
          }
        }
        .close{.wh(28); .rt(48,43);}
        .page-move{.fix; .rb(42,96);
          a{.wh(40);
            &.prev{.mb(14);}
          }
        }
      }
      .more-info{
        .inner{ .p(43,60,0,48); aspect-ratio: inherit; .w(50%); .m; .abs; .rt(0,0); .t-x(100%); transition:opacity 1s ease, transform 1s ease;
          .more-close{.wh(28); .mb(56); transform:rotate(180deg);}
          [sns-share]{.wh(28); .rt(48,43);
            .share-popup{.t(36);}
          }
          .depth{.mb(18); .fs(12); }
          .title,.content{.w(70%);}
          .title{.mb(40); .fs(18,1.5,-0.25); }
          .content{.mb(50); .fs(16,1.5,-0.25);}
          .link{
            span{ .fs(14,1.5,-0.25);}
            i{.wh(18); .ml(6);}
          }
        }
      }
      &.more-open{
        .more-info .inner{.t-x(1);}
      }
      &.play-wide{
        .viewer{
          .info {
            .inner {.p(95,48);}
            a{.rb(42,95); .br(32);}
          }
          .close{.rt(48,42);}
          .page-move{.rb(42,200);}
        }
        .more-info{
          .inner{ .p(43,0,0,60); .w(50%);
            [sns-share]{.rt(48,43);}
          }
        }
      }
    }
  }
}
@media (min-width: @screen-dm-min) {
  [play-viewer] {
    &.play-shorts, &.play-shortsWide {
      .more-info .inner{.abs;}
    }
    .viewer{
      .info{
        .inner{.p(57,20,48);}
        .text{
          p:first-of-type{.fs(12); .pb(10);}
          p+p{.fs(15,1.47,-0.25);}
        }
        a{.br(20); .p(9,15); .rb(20,48);
          span{.fs(15); .ls(-0.32);
            #app:not(.ko) & {.fs(14);}
          }
          i{.wh(17); .ml(2);
            #app:not(.ko) &{.wh(16);}
          }
        }
      }
      .close{.wh(28); .rt(48,43);}
      .page-move{.fix; .rb(42,96);
        a{.wh(40);
          &.prev{.mb(14);}
        }
      }
    }
    .more-info{
      .inner{ .p(43,60,0,48); aspect-ratio: inherit; .w(50%); .m; .abs; .rt(0,0); .t-x(100%); transition:opacity 1s ease, transform 1s ease;
        .more-close{.wh(28); .mb(56); transform:rotate(180deg);}
        [sns-share]{.wh(28); .rt(48,43);
          .share-popup{.t(36);}
        }
        .depth{.mb(18); .fs(12); }
        .title,.content{.w(70%);}
        .title{.mb(40); .fs(18,1.5,-0.25); }
        .content{.mb(50); .fs(16,1.5,-0.25);}
        .link{
          span{ .fs(14,1.5,-0.25);}
          i{.wh(18); .ml(6);}
        }
      }
    }
    &.more-open{
      .more-info .inner{.t-x(1);}
    }
    &.play-wide{
      .viewer{
        .info {
          .inner {.p(95,48);}
          a{.rb(42,95); .br(32);
            i{.wh(28); .ml(4);
              #app:not(.ko) &{.wh(24);}
            }
          }
        }
        .close{.rt(48,42);}
        .page-move{.rb(42,200);}
      }
      .more-info{
        .inner{ .p(43,0,0,60); .w(50%);
          [sns-share]{.rt(48,43);}
        }
      }
    }
    &.more-open{
      .more-info .inner{.t-x(0);}
    }
  }
}
@media (min-width: @screen-dl-min) {
  [play-viewer] {
    .viewer{
      .info{
        .inner{.p(73,26,64);}
        .text{
          p:first-of-type{.fs(17); .pb(14);}
          p+p{.fs(20,1.5,-0.25);}
        }
        a{.br(28); .p(12,20); .rb(26,64);
          span{.fs(20); .ls(-0.42);
            &.more-en{.fs(19);}
          }
          i{.wh(23); .ml(2);}
        }
      }
      .close{.wh(37); .rt(60,56);}
      .page-move{.fix; .rb(52,128);
        a{.wh(53);
          &.prev{.mb(20);}
        }
      }
    }
    .more-info{
      .inner{ .p(57,80,0,60);
        .more-close{.wh(37); .mb(75); }
        [sns-share]{.wh(28); .rt(60,57);}
        .depth{.mb(23); .fs(16); }
        .title{.mb(53); .fs(24,1.5,-0.33); }
        .content{.mb(67); .fs(21,1.5,-0.25);}
        .link{
          span{ .fs(19,1.5,-0.33);}
          i{.wh(24); .ml(10);}
        }
      }
    }
    &.play-wide{
      .viewer{
        .info {
          .inner {.p(135,64);
            .text{
              p:first-of-type{.fs(16); .pb(18);}
              p+p{.fs(21,1.5,-0.25);}
            }
          }
          a{.br(36); .p(10,24); .rb(56,135);
            span{.fs(24);
              #app:not(.ko) & { .fs(23); }
            }
            i{.wh(27); .ml(6);}
          }
        }
        .close{.rt(60,56); .wh(37);}
        .page-move{.rb(56,247);
          a{.wh(53);
            &.prev{.mb(19);}
          }
        }
      }
      .more-info{
        .inner{ .p(57,0,0,80);
          .more-close{.wh(37); .mb(75); }
          [sns-share]{.wh(37); .rt(60,57);
            .share-popup{.t(46);}
          }
          .depth{.mb(23); .fs(16); }
          .title{.mb(53); .fs(24,1.5,-0.25); }
          .content{.mb(67); .fs(21,1.5,-0.25);}
          .link{.rel; .lb;
            span{ .fs(19,1.5,-0.25);}
            i{.wh(24); .ml(10);}
          }
        }
      }
    }
  }
}