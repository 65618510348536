.t {
  transform: none;
}
[scroll-holder] {
  position: relative;
}
[scroll-pseudo-body] {
  display: none;
}
[scroll-container] {
  position: relative;
}
[scroll-body] {
  position: relative;
}
.inertia [scroll-pseudo-body] {
  display: block;
}
.inertia [scroll-container] {
  position: fixed;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
